import {Container, makeStyles} from '@material-ui/core'
import KinuHeader from "kinu/components/Header"
import KinuSidebar from 'kinu/components/Sidebar'
import clsx from 'clsx'
import {useSystemContext} from 'context/system'
import {sidebarWidth} from 'config/sidebar'
import {useAuthContext} from 'context/auth'

export default function MainLayout(props) {
  const styles = useStyles();
  const system = useSystemContext();
  const {userId, userData, userType} = useAuthContext();

  return (
    <div className={styles.container}>
      <KinuHeader />
      <KinuSidebar />
      <div className={clsx(styles.container, system.drawer && styles.containerShift)}>
        <div className={styles.appBarSpacer} />
          <Container maxWidth="md" className={styles.mainContainer}>
            {(!!userId && !!userData && !!userType) ?
              props.children : null
            }
          </Container>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  containerShift: {
    marginLeft: sidebarWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  mainContainer: {
    marginTop: theme.spacing(2)
  }
}));