import {ListItem, ListItemAvatar, Avatar, ListItemText, makeStyles, Typography} from '@material-ui/core'

export default function ChildrenListItem(props) {
  const styles = useStyles();

  const item = props.item;

  return (
    <ListItem
      button
      onClick={()=>props.select(item)}
    >
      <ListItemAvatar>
        <Avatar alt={item.name} src="" />
      </ListItemAvatar>
      <ListItemText primary={item.name} secondary={item.kana} />
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));