import KinuEditPage from 'kinu/pages/ModelCreateEdit'
import ScheduleModel from 'models/Schedule'
import {useParams} from 'react-router-dom'
import {db} from 'plugins/firebase'
import {useAuthContext} from 'context/auth'

export default function ScheduleEdit() {

  const {userId} = useAuthContext();
  const params = useParams();
  const doc = db.doc('users/'+ userId + '/schedules/' + params.id);

  return (
    <KinuEditPage
      model={ScheduleModel}
      doc={doc}
      mode="edit"
      redirect="/office/schedule/list"
    />
  )
}
