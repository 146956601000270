import { Collections } from "@material-ui/icons";

export default {

  //必須
  required: v => {
    return !!v || "必須項目です";
  },

  //チェック必須
  mustCheck: v => {
    return v === true || "チェックして下さい";
  },

  //~文字以上
  moreThan: num => {
    return v => {
      if(v === undefined || v === null || v === "") {
        return true;
      }
      else if(!!v) {
        if(v.length >= num) return true;
      }
      return `${num}文字以上`
    }
  },

  //~文字以下
  lessThan: num => {
    return v => {
      //空文字や未入力の時
      if (v === undefined || v === null || v === ""){
        return true;
      }
      else if (!!v) {
        if (v.length <= num) return true;
      }
      return `${num}文字以下`;
    };
  },

  //半角英数字
  hwc: v => {
    const checkStr = v || "";
    if(checkStr.match(/^[A-Za-z0-9]*$/)){
      return true;
    }else{
      return "半角英数字で入力して下さい";
    }
  },

  //全角カナ
  kana: v => {
    const checkStr = v || "";
    const res = checkStr.match(/^[ァ-ヶー　]*$/);
    return !!res || '全角カナで入力して下さい'
  },

  //同じ値は不可（lazyRulesで設定すること）
  duplicate: (collection, key) => {
    return async v => {
      const res = await collection.where(key, '==', v).get();
      if(res.empty) return true;
      return "既に使用されています";
    }
  }

}