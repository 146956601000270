import {makeStyles, Typography} from '@material-ui/core'
import TimeToStr from 'kinu/components/TimeToStr'

export default function MyChatItem(props) {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <Typography>{props.item.message}</Typography>
      </div>
      <div className={styles.date}>
        <TimeToStr>{props.item.createAt.seconds}</TimeToStr>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end"
  },
  message: {
    backgroundColor: "#ededaa",
    padding: theme.spacing(1, 2),
    borderRadius: 12,
    borderTopLeftRadius: 0,
    whiteSpace: "pre-wrap"
  },
  date: {
    color: "#777",
    paddingBottom: 4,
    paddingLeft: 4,
    fontSize: 12
  },
}));