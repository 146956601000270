import rules from 'kinu/plugins/rules'
import {db} from 'plugins/firebase'
import imageSet from 'config/imageSet'

export default {
  name: "スケジュール",
  path: "",
  columns: {
    date: {
      label: "日付",
      type: "date",
      required: true,
      rules: [rules.required]
    },
    title: {
      label: "スケジュール内容",
      type: "text",
      required: true,
      rules: [rules.required],
    },
  }
}