import React, {useState, useRef} from 'react'
import {
  makeStyles,
  Typography,
  TextField,
  Button
} from '@material-ui/core'
import MainLayout from 'kinu/layouts/main'
import {useAuthContext} from 'context/auth'
import {useSystemContext} from 'context/system'
import ReAuthDialog from 'kinu/components/ReAuthDialog'

export default function ChangeEmail() {
  const styles = useStyles();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [lock, setLock] = useState(false);
  const {changeEmail} = useAuthContext();
  const {showLoading, hideLoading, showSnackbar} = useSystemContext();

  //メールアドレス変更処理
  const doChange = async () => {
    if(lock) return false;
    showLoading();
    setLock(true);
    const res = await changeEmail(email);
    if(res !== true) {
      switch(res) {
        case "auth/invalid-email":
          setError("正しいメールアドレスを入力して下さい");
          break;
        case "auth/email-already-in-use":
          setError("このメールアドレスは使用出来ません");
          break;
        case "auth/requires-recent-login":
          reAuth()
          break;
        default:
          setError("エラーが発生しました");
          break;
      }
      hideLoading();
      setLock(false)
      return false;
    }

    showSnackbar("メールアドレスを変更しました","success");
    setError("");
    setEmail("");
    setLock(false);
    hideLoading();
  }

  //再認証
  const reAuthDialog = useRef(null);
  const reAuth = async () => {
    const res = await reAuthDialog.current.show();
    if(!res) {
      return false;
    }

    //再度メールアドレス変更
    doChange();
  }

  return (
    <MainLayout>
      <Typography component="h1" variant="h5">メールアドレス変更</Typography>
      <form onSubmit={e=>{
        e.preventDefault();
      }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="新しいメールアドレス"
          name="email"
          autoComplete="email"
          onChange={e=>setEmail(e.target.value)}
          value={email}
          autoFocus
          onKeyDown={e=>{
            if(e.keyCode === 13) {
              doChange();
            }
          }}
        />
        <Typography className={styles.errorMessage}>{error}</Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={doChange}
        >
          メールアドレス変更
        </Button>
      </form>
      <ReAuthDialog ref={reAuthDialog} />
    </MainLayout>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  errorMessage: {
    color: "red"
  }
}));