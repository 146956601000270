import KinuListPage from 'kinu/pages/ModelList'
import ScheduleModel from 'models/Schedule'
import {db} from 'plugins/firebase'
import { Button } from '@material-ui/core'
import {useAuthContext} from 'context/auth'

export default function TestList() {

  const {userId} = useAuthContext();
  const collection = db.collection('users').doc(userId).collection('schedules');
  const columns = [
    {
      field: "date",
      headerName: "日付",
      renderCell: params => (
        <>
          {params.value.year}/{params.value.month}/{params.value.day}
        </>
      )
    },
    {
      field: "title",
      headerName: "スケジュール名",
      width: 200
    },
  ]

  return (
    <KinuListPage
      model={ScheduleModel}
      collection={collection}
      delete  //削除機能をつけるかどうか
      edit="/office/schedule/edit/"  //編集ボタンのリンク先（これにID追加される）
      editUrlDepth={1}
      columns={columns} //表示カラムを自分で指定
      createAt  //作成日時を表示するか
      //updateAt  //編集日時を表示するか
    />
  )
}
