import KinuEditPage from 'kinu/pages/ModelCreateEdit'
import NoteModel from 'models/Note'
import {useParams} from 'react-router-dom'
import {db} from 'plugins/firebase'
import {useAuthContext} from 'context/auth'
import {clone} from 'kinu/plugins/functions'

export default function InfoEdit() {

  const {userId} = useAuthContext();
  const params = useParams();
  const doc =
  db.doc(`users/${params.userId}/children/${params.childId}/notes/${params.noteId}`);

  //モデルのselect items部分の書き換え
  let model = clone(NoteModel);
  model.columns.childPath.fsItems = 
    db.collectionGroup('children').where('officeId', '==', userId);

  return (
    <KinuEditPage
      model={model}
      doc={doc}
      mode="edit"
      redirect={`/office/note/list/${params.userId}/${params.childId}`}
    />
  )
}
