import Login from 'pages/login'
import Top from 'pages/top'
import ForgetPassword from 'pages/ForgetPassword'
import SignUp from 'pages/SignUp'

//ログインユーザー共通
import ChangeEmail from 'kinu/pages/ChangeEmail'
import ChangePassword from 'kinu/pages/ChangePassword'

//管理者
import AdminDashboard from 'pages/admin/dashboard'
import AdminOfficeList from 'pages/admin/office/list'
import AdminOfficeCreate from 'pages/admin/office/create'
import AdminOfficeEdit from 'pages/admin/office/edit'
import AdminInfoList from 'pages/admin/info/list'
import AdminInfoCreate from 'pages/admin/info/create'
import AdminInfoEdit from 'pages/admin/info/edit'

//事業所
import OfficeDashboard from 'pages/office/dashboard'
import OfficeAdminInfoList from 'pages/office/adminInfo/list'
import OfficeUsersList from 'pages/office/user/list'
import OfficeUserRequest from 'pages/office/user/request'
import OfficeProfile from 'pages/office/profile'
import OfficeInfoList from 'pages/office/info/list'
import OfficeInfoCreate from 'pages/office/info/create'
import OfficeInfoEdit from 'pages/office/info/edit'
import OfficeChatList from 'pages/office/chat/list'
import OfficeNoteList from 'pages/office/note/list'
import OfficeNoteCreate from 'pages/office/note/create'
import OfficeNoteEdit from 'pages/office/note/edit'
import OfficeScheduleList from 'pages/office/schedule/list'
import OfficeScheduleCreate from 'pages/office/schedule/create'
import OfficeScheduleEdit from 'pages/office/schedule/edit'
import OfficeAlbumList from 'pages/office/album/list'
import OfficeAlbumCreate from 'pages/office/album/create'
import OfficeAlbumEdit from 'pages/office/album/edit'


/*
 * auth :
 *   null or "public" 誰でもアクセス可能
 *   "login" ログイン済みのみアクセス可能
 *   "noLogin" 未ログインの場合のみアクセス可能
 *
 * types :
 *   authがloginの場合に、アクセス可能なユーザータイプを配列で指定
 */

export const routerData = [
  {
    name: "トップページ",
    exact: true,
    path: "/",
    component: Top
  },
  {
    name: "ログイン",
    auth: "noLogin",
    exact: true,
    path: "/login",
    component: Login
  },
  {
    name: "新規登録",
    auth: "noLogin",
    exact: true,
    path: "/signup",
    component: SignUp
  },
  {
    name: "パスワード再設定",
    auth: "noLogin",
    exact: true,
    path: "/forgetPassword",
    component: ForgetPassword
  },

  //ログインユーザー共通
  {
    name: "メールアドレス変更",
    auth: "login",
    exact: true,
    path: "/changeEmail",
    component: ChangeEmail
  },
  {
    name: "パスワード変更",
    auth: "login",
    exact: true,
    path: "/changePassword",
    component: ChangePassword
    
  },

  //管理者
  {
    name: "管理者トップ",
    auth: "login",
    types: ['admin'],
    exact: true,
    path: "/admin/",
    component: AdminDashboard
  },
  {
    name: "事業所一覧",
    auth: "login",
    types: ["admin"],
    exact: true,
    path: "/admin/office/list",
    component: AdminOfficeList
  },
  {
    name: "事業所追加",
    auth: "login",
    types: ["admin"],
    exact: true,
    path: "/admin/office/create",
    component: AdminOfficeCreate
  },
  {
    name: "事業所編集",
    auth: "login",
    types: ["admin"],
    path: "/admin/office/edit/:id",
    component: AdminOfficeEdit
  },
  {
    name: "管理者からのお知らせ追加",
    auth: "login",
    types: ['admin'],
    exact: true,
    path: "/admin/infos/create",
    component: AdminInfoCreate
  },
  {
    name: "管理者からのお知らせ一覧",
    auth: "login",
    types: ['admin'],
    exact: true,
    path: "/admin/infos/list",
    component: AdminInfoList
  },
  {
    name: "管理者からのお知らせ編集",
    auth: "login",
    types: ['admin'],
    path: "/admin/infos/edit/:id",
    component: AdminInfoEdit
  },

  //事業所
  {
    name: "事業所トップ",
    auth: "login",
    types: ["office"],
    path: "/office/",
    exact: true,
    component: OfficeDashboard
  },
  {
    name: "事業所情報",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/profile",
    component: OfficeProfile
  },
  {
    name: "管理者からのお知らせ一覧",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/informations",
    component: OfficeAdminInfoList
  },
  {
    name: "園児一覧",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/user/list",
    component: OfficeUsersList
  },
  {
    name: "園児追加リクエスト",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/user/request",
    component: OfficeUserRequest
  },
  {
    name: "チャット",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/chat",
    component: OfficeChatList
  },
  {
    name: "保育ノート一覧",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/note/list",
    component: OfficeNoteList
  },
  {
    name: "保育ノート一覧",
    auth: "login",
    types: ['office'],
    //exact: true,
    path: "/office/note/list/:userId/:childId",
    component: OfficeNoteList
  },
  {
    name: "保育ノート記入",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/note/create",
    component: OfficeNoteCreate
  },
  {
    name: "保育ノート記入",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/note/create/:userId/:childId",
    component: OfficeNoteCreate
  },
  {
    name: "保育ノート編集",
    auth: 'login',
    types: ['office'],
    exact: true,
    path: "/office/note/edit/:userId/:childId/:noteId",
    component: OfficeNoteEdit
  },
  {
    name: "お知らせ一覧",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/info/list",
    component: OfficeInfoList
  },
  {
    name: "お知らせ追加",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/info/create",
    component: OfficeInfoCreate
  },
  {
    name: "お知らせ編集",
    auth: "login",
    types: ['office'],
    exact: false,
    path: "/office/info/edit/:id",
    component: OfficeInfoEdit
  },
  {
    name: "スケジュール一覧",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/schedule/list",
    component: OfficeScheduleList
  },
  {
    name: "スケジュール追加",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/schedule/create",
    component: OfficeScheduleCreate
  },
  {
    name: "スケジュール編集",
    auth: "login",
    types: ['office'],
    exact: false,
    path: "/office/schedule/edit/:id",
    component: OfficeScheduleEdit
  },
  {
    name: "アルバム一覧",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/album/list",
    component: OfficeAlbumList
  },
  {
    name: "アルバム作成",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/album/create",
    component: OfficeAlbumCreate
  },
  {
    name: "アルバム編集",
    auth: "login",
    types: ['office'],
    exact: true,
    path: "/office/album/edit/:id",
    component: OfficeAlbumEdit
  }

]