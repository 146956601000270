import React, {useContext} from 'react'
import firebase from 'plugins/firebase'
import {useAuthContext} from 'context/auth'
import {useModelContext} from 'context/model'
import userModel from 'models/Users'
import { TurnedIn } from '@material-ui/icons';

const UserContext = React.createContext();

export const UserProvider = ({children}) => {

const {userType} = useAuthContext();
const ModelContext = useModelContext();

  //-----Create-----

  //別のユーザーの作成（管理者から作成）
  /*
   * return
   * true: 登録成功時
   * false: 登録時にエラー
   * Object: バリデーションエラー
   */
  const createUser = async (type, data) => {
    const model = userModel[type];
    if(!model) return false;

    const validationRes = await validation(type, data, 'create');
    if(validationRes.result === false) {
      return validationRes.errors;
    }

    //Auth登録処理
    const email = validationRes.data.email;
    const password = validationRes.data.password;
    const createAuthRes = await createAuthUser(email, password, true);
    if(!createAuthRes.result) {
      return {email: createAuthRes.error};
    }

    //ユーザーデータ登録
    const userId = createAuthRes.response.user.uid;
    const userData = {
      ...validationRes.data,
      email,
      type,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
      updateAt: firebase.firestore.FieldValue.serverTimestamp(),
      hasProfile: true
    }
    delete userData.password;

    const saveRes = await updateUserData(userId, userData, true);

    return true;
  }

  //自らユーザー作成（サインアップフォームから）
  const signUp = async (email, password, type) => {
    //ユーザーモデル設定でサインアップ不可になっていないか
    const model = userModel[type];
    if(!model.canSignUp) {
      return "現在新規登録出来ません";
    }

    const createAuthRes = await createAuthUser(email, password, false);
    if(!createAuthRes.result) {
      return createAuthRes.error;
    }

    //新規ユーザーデータ登録
    const userId = createAuthRes.response.user.uid;
    const userData = {
      email,
      type,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
      updateAt: firebase.firestore.FieldValue.serverTimestamp(),
      hasProfile: false
    }

    const saveRes = await updateUserData(userId, userData, true);

    return true;
  }

  //-----Read-----
  const getUser = async (id) => {
    const getRes = await firebase.firestore().collection('users').doc(id).get();
    if(!getRes.exists) {
      return false;
    }
    let res = ModelContext.makeItemData(getRes);
    return res;
  }

  //-----Update-----
  //data.pathが編集先ID
  const editUser = async (type, data) => {
    const validationRes = await validation(type, data, 'edit');

    //バリデーション通らなかった場合、エラー内容を返す
    if(validationRes.result === false) {
      return validationRes.errors;
    }

    //編集処理
    //編集時に自動で追加する項目
    const saveData = {
      ...validationRes.data,
      updateAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    const editRes = await firebase.firestore().doc(data.path)
    .set(saveData, {merge: true})

    return true;
  }

  //-----Delete------

  
  //バリデーション
  const validation = async (type, validationData, mode = 'create') => {
    const model = userModel[type];

    let res = await ModelContext.validation(model, validationData, mode);

    //メールアドレス・パスワードのバリデーション
    if(!mode || mode === 'create') {
      //メールアドレス
      if(!!model.emailRules) {
        let hasError = false;
        for(let i = 0; i < model.emailRules.length; i++) {
          const ruleRes = model.emailRules[i](validationData.email);
          if(ruleRes !== true) {
            res.errors.email = ruleRes;
            res.result = false;
            hasError = true;
            break;
          }
        }
        if(hasError === false) {
          res.data.email = validationData.email;
        }
      }
      else {
        res.data.email = validationData.email;
      }
      //パスワード
      if(!!model.passwordRules) {
        let hasError = false;
        for(let i = 0; i < model.passwordRules.length; i++) {
          const ruleRes = model.passwordRules[i](validationData.password);
          if(ruleRes !== true) {
            res.errors.password = ruleRes;
            res.result = false;
            hasError = true;
            break;
          }
        }
        if(hasError === false) {
          res.data.password = validationData.password;
        }
      }
      else {
        res.data.password = validationData.password;
      }
    }

    return res;
  }

  return(
    <UserContext.Provider value={{
      createUser,
      signUp,
      getUser,
      editUser
    }}>
      {children}
    </UserContext.Provider>
  )

}

//-----ローカル関数
//firebase authのユーザー作成
const createAuthUser = async (email, password, forCreate = false) => {
  const auth = forCreate ? firebase.app('forCreate').auth() : firebase.auth();
  const res = await auth.createUserWithEmailAndPassword(email, password)
  .catch(e=>e)
  if(res.code) {
    let error = "登録エラー"
    switch(res.code) {
      case "auth/email-already-in-use":
        error = "このメールアドレスは登録出来ません";
        break;
      case "auth/invalid-email":
        error = "正しいメールアドレスを入力してください"
        break;
      default:
        break;
    }
    return {
      result: false,
      error: error,
      response: res
    }
  }
  return {
    result: true,
    response: res
  }
}

//firestoreにユーザーデータ保存
const updateUserData = async (id, userData, create = false) => {
  if(!create) {
    userData.updateAt = firebase.firestore.FieldValue.serverTimestamp();
    userData.hasProfile = true;
  }
  await firebase.firestore().collection('users').doc(id).set(userData, {merge:true});
  return true;
}

//useContext
export const useUserContext = () => useContext(UserContext);