import {
  makeStyles,
  CircularProgress,
  Fade
} from '@material-ui/core'
import {useSystemContext} from '../../context/system'

export default function Loading() {
  const styles = useStyles();
  const {loading} = useSystemContext();

  return (
    <Fade in={loading}>
      <div className={styles.container}>
        <CircularProgress color="primary" size={80} />
      </div>
    </Fade>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 9999,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
  },
}));