import {makeStyles, Typography} from '@material-ui/core'
import TimeToStr from 'kinu/components/TimeToStr'
import KinuDialog from 'kinu/components/Dialog'
import { useRef } from 'react';

export default function AdminInfoList(props) {
  const styles = useStyles();
  const dialog = useRef(null);

  const handleClick = item => {
    dialog.current.show(
      item.title,
      item.content,
      {
        single: true
      }
    )
  }

  return (
    <div className={styles.container}>
      {props.infos.length <= 0 ? (
        <Typography>管理者からのお知らせはありません</Typography>
      ) : 
        props.infos.map(item=>(
          <div className={styles.item}>
            <Typography className={styles.date}><TimeToStr noTime>{item.createAt.seconds}</TimeToStr></Typography>
            <Typography className={styles.title} onClick={()=>handleClick(item)}>{item.title}</Typography>
          </div>
        ))
      }
      <KinuDialog
        ref={dialog}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  },
  item: {
    padding: 4,
    borderBottomWidth: 1,
    borderBottomStyle: 'dashed',
    borderBottomColor: "#aaa",
    display: "flex"
  },
  date: {
    fontSize: 16,
    marginRight: 12
  },
  title: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '-webkit-link',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));