import {routerData} from 'config/router'
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFound from 'pages/404'
import {useAuthContext} from 'context/auth';
import FirstLoading from 'kinu/components/FirstLoading'

export default function Auth() {

  const { firstLoading, userId, userType } = useAuthContext();

  let pages = [];

  for(let i = 0; i < routerData.length; i++) {
    //ログイン不要
    if(!routerData[i].auth || routerData[i].auth === 'public') {
      pages.push(
        <Route exact={routerData[i].exact} path={routerData[i].path} component={routerData[i].component} key={i}/>
      )
    }

    //未ログイン限定
    else if(routerData[i].auth === 'noLogin') {
      if(!firstLoading && !userId) {
        pages.push(
          <Route exact={routerData[i].exact} path={routerData[i].path} component={routerData[i].component} key={i}/>
        )
      }
    }

    //ログイン済みのみ
    else if(routerData[i].auth === 'login') {
      if(!firstLoading && userId) {

        //表示タイプの指定がある場合
        if(routerData[i].types) {
          if(routerData[i].types.indexOf(userType) !== -1) {
            pages.push(
              <Route exact={routerData[i].exact} path={routerData[i].path} component={routerData[i].component} key={i}/>
            )
          }
        }

        //表示タイプの指定無し
        else {
          pages.push(
            <Route exact={routerData[i].exact} path={routerData[i].path} component={routerData[i].component} key={i}/>
          )
        }
      }
    }
  }

  //リダイレクト先
  if(!firstLoading) {
    //ログイン済み
    if(userId) {
      pages.push(
        <Redirect to={"/" + userType} key="toadmin"/>
      )
    }
    //未ログイン
    else {
      pages.push(
        <Redirect to="/login" key="tologin" />
      )
    }
  }

  return (
    <Switch>
      {pages}
      <Route component={firstLoading? FirstLoading : NotFound} key="notfound"/>
    </Switch>
  )
}
