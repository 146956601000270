import {makeStyles, CircularProgress} from '@material-ui/core'

export default function ComponentName() {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <CircularProgress color="primary" size={60} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));