import {makeStyles} from '@material-ui/core'

export default function BlankLayout(props) {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {props.children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));