import KinuListPage from 'kinu/pages/ModelList'
import ChildModel from 'models/Child'
import {db} from 'plugins/firebase'
import { makeStyles, Button } from '@material-ui/core'
import {useAuthContext} from 'context/auth'
import {useModelContext} from 'context/model'
import { useHistory } from 'react-router-dom'

export default function UserList() {

  const styles = useStyles();
  const history = useHistory();

  const {userId} = useAuthContext();
  const {editItem} = useModelContext();
  const collection = db.collectionGroup('children').where('officeId','==',userId).where('accept','==', true)
  const genderList = {
    boy: "男の子",
    girl: "女の子"
  }
  const columns = [
    {
      field: "name",
      headerName: "園児名",
      width: 140,
    },
    {
      field: "gender",
      headerName: "性別",
      renderCell: params =>(
        <>{genderList[params.value]}</>
      ),
      width: 100
    },
    {
      field: "birthday",
      headerName: "誕生日",
      renderCell: params => (
        <>{params.value.year}年{params.value.month}月{params.value.day}日</>
      ),
      width: 140
    },
    {
      field: "notes",
      headerName: "保育ノート",
      width: 160,
      renderCell: params =>(
        <>
          <Button
            color="primary"
            variant="outlined"
            onClick={()=>history.push(`/office/note/create/${params.row.editPath[1]}/${params.row.editPath[0]}`)}
          >記入</Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={()=>history.push(`/office/note/list/${params.row.editPath[1]}/${params.row.editPath[0]}`)}
          >閲覧</Button>
        </>
      )
    }
  ]

  return (
    <KinuListPage
      model={ChildModel}
      collection={collection}
      delete  //削除機能をつけるかどうか
      //edit="/office/info/edit/"  //編集ボタンのリンク先（これにID追加される）
      //editUrlDepth={1}
      columns={columns} //表示カラムを自分で指定
      createAt  //作成日時を表示するか
      //updateAt  //編集日時を表示するか
    />
  )
}

const useStyles = makeStyles(theme => ({
  name: {
    display: 'block',
    fontWeight: 'bold'
  },
  kana: {
    color: "#aaa"
  }
}));