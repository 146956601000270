import React, {useState, forwardRef, useImperativeHandle} from 'react'
import {makeStyles, Button} from '@material-ui/core'
import KinuTextForm from 'kinu/components/form/text'
import KinuRadioForm from 'kinu/components/form/radio'
import KinuCheckboxForm from 'kinu/components/form/checkbox'
import KinuSelectForm from 'kinu/components/form/select'
import KinuImageForm from 'kinu/components/form/image'
import KinuTextAreaForm from 'kinu/components/form/textarea'
import KinuTelForm from 'kinu/components/form/tel'
import KinuAddressForm from 'kinu/components/form/address'
import KinuDateForm from 'kinu/components/form/date'
import { useModelContext } from 'context/model'
import { useAuthContext } from 'context/auth'

export default forwardRef(function KinuModelForm(props, ref) {
  const columns = props.model.columns;

  //Authコンテキスト
  const {userType} = useAuthContext();

  //モデルコンテキスト関数
  const {makeInitialState} = useModelContext();

  //フォームデータ
  const [data, setData] = useState(makeInitialState(props.model, props.mode));
  const [errors, setErrors] = useState({});

  //親からのデータ更新
  useImperativeHandle(ref, ()=>{
    return {
      setErrors: (errors) => setErrors(errors),
      setData: (data) => setData(data)
    }
  })

  //sendボタンクリック時処理
  const handleSend = async () => {
    props.send(data);
  }


  //フォーム作成
  let formItems = [];
  for(let key of Object.keys(columns)) {

    const column = columns[key];

    //hideEdit, hideCreateの判定
    if(column.hideCreate && (!props.mode || props.mode === 'create' )) {
      continue;
    } else if(column.hideEdit && props.mode === 'edit') {
      continue;
    }

    //showTypesの判定
    if(!!column.showTypesForCreate && (!props.mode || props.mode === 'create')) {
      if(column.showTypesForCreate.indexOf(userType) === -1) continue;
    }
    else if(!!column.showTypesForEdit && props.mode === 'edit') {
      if(column.showTypesForEdit.indexOf(userType) === -1) continue; 
    }

    //disabledの判定
    const disabled = () => {
      //disabledEdit
      if(column.disabledEdit && props.mode === 'edit') return true;

      //disabledEditTypes
      else if(!!column.disabledEditTypes && props.mode === 'edit') {
        if(column.disabledEditTypes.indexOf(userType) !== -1) return true;
      }
      return false;
    }
    
    //テキスト
    if(column.type === 'text') {
      formItems.push(
        <KinuTextForm
          key={key}
          columnKey={key}
          column={column}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          disabled={disabled()}
        />
      )
    }

    //テキストエリア
    else if(column.type === 'textarea') {
      formItems.push(
        <KinuTextAreaForm
          key={key}
          columnKey={key}
          column={column}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          disabled={disabled()}
        />
      )
    }

    //電話番号
    else if(column.type === 'tel') {
      formItems.push(
        <KinuTelForm
          key={key}
          columnKey={key}
          column={column}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          disabled={disabled()}
        />
      )
    }

    //チェックボックス
    else if(column.type === 'checkbox') {
      formItems.push(
        <KinuCheckboxForm
          key={key}
          columnKey={key}
          column={column}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          disabled={disabled()}
        />
      )
    }

    //ラジオボタン
    else if(column.type === 'radio') {
      formItems.push(
        <KinuRadioForm
          key={key}
          columnKey={key}
          column={column}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          disabled={disabled()}
        />
      )
    }

    //セレクトボックス
    else if(column.type === "select") {
      formItems.push(
        <KinuSelectForm
          key={key}
          columnKey={key}
          column={column}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          disabled={disabled()}
        />
      )
    }

    //日付選択
    else if(column.type === "date") {
      formItems.push(
        <KinuDateForm
          key={key}
          columnKey={key}
          column={column}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          disabled={disabled()}
        />
      )
    }

    //住所
    else if(column.type === "address") {
      formItems.push(
        <KinuAddressForm
          key={key}
          columnKey={key}
          column={column}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          disabled={disabled()}
        />
      )
    }

    //画像
    else if(column.type === "image") {
      formItems.push(
        <KinuImageForm
          key={key}
          columnKey={key}
          column={column}
          data={data}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          disabled={disabled()}
        />
      )
    }

  }

  return (
    <form>
      {formItems}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSend}
        disabled={Object.values(errors).reduce((total,item)=>total+(item?1:0),0)? true : false}
      >{props.mode === 'edit' ? '編集' : '新規登録'}</Button>
    </form>
  )
})

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));