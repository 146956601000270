import {
  Grid,
  makeStyles,
  Typography, 
  TableBody,
  TableCell,
  Table,
  TableRow,
  Button
} from '@material-ui/core'
import MainLayout from '../../kinu/layouts/main'
import { useSystemContext } from "../../context/system";
import { useAuthContext } from '../../context/auth'
import Image from 'kinu/components/Image'
import Card from 'kinu/components/Card'
import {db} from 'plugins/firebase'
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'

import AdminInfoList from './parts/AdminInfoList'

export default function OfficeDashboard() {
  const styles = useStyles();
  const {showLoading, showSnackbar, cacheGet} = useSystemContext();
  const {userId, userType, userData} = useAuthContext();

  const [infos, setInfos] = useState([]);
  
  const getInfos = async () => {
    const res = await db.collection('infos').limit(5).get();
    if(res.empty) {
      setInfos([]);
      return;
    }

    let tempInfos = []
    for(let i = 0; i < res.docs.length; i++) {
      tempInfos.push({
        ...res.docs[i].data(),
        id: res.docs[i].id
      })
    }
    setInfos(tempInfos)
  }
  const click = async () => {
    const ref = db.collection(`users`);
    const res = await cacheGet(ref, 'userList', true);
    console.log(res);
  }

  useEffect(()=>{
    getInfos();
  },[])

  return (
    <MainLayout>
      <div className={styles.container}>
        <Typography className={styles.title}>事業所ダッシュボード</Typography>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Card title="管理者からのお知らせ">
              {infos.length > 0 ? (
                <AdminInfoList infos={infos} />
              ) : (
                <Typography>管理人からのお知らせはありません</Typography>
              )}
              <Link to="/office/informations">全てのお知らせを見る</Link>
            </Card>
          </Grid>

          {userData? (
          <Grid item xs={6}>
            <Card title="事業所情報">
              <div>
                {userData.image? (
                  <Image image={userData.image[0].images.default} cover/>
                ) : null}
              </div>
              <div>{userData.name}</div>
            </Card>
          </Grid>
          ) : null}
          
          {userData? (
            <Grid item xs={6}>

              <Card title="事業所情報">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>未承認園児数</TableCell>
                      <TableCell>{userData.requestChildCount}人</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>承認済み園児数</TableCell>
                      <TableCell>{userData.childCount}人</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>

              {/* <Card title="クラステスト">
                <Button
                  onClick={()=>click()}
                >cache</Button>
              </Card> */}

            </Grid>
          ) : null}

        </Grid>
      </div>
    </MainLayout>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 'bold'
  }
}));