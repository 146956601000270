import {
  Container,
  makeStyles,
  Typography,
  TextField,
  Button,
  Grid
} from '@material-ui/core'
import BlankLayout from 'kinu/layouts/blank'
import { useState } from 'react';
import {Link} from 'react-router-dom'
import {useSystemContext} from 'context/system'
import {useUserContext} from 'context/user'
import UserModel from 'models/Users'

export default function SignUp(props) {
  const styles = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {showLoading, hideLoading} = useSystemContext();
  const {signUp} = useUserContext();

  //サインアップ処理
  const handleSignUp = async () => {
    showLoading();
    const signupRes = await signUp(email, password, props.type);
    if(signupRes !== true) {
      setErrorMessage(signupRes)
      hideLoading();
    }
  }

  return (
    <BlankLayout>
      {!UserModel[props.type].canSignUp?
        <Typography>エラー</Typography>
      :
      <Container maxWidth="xs">
        <div className={styles.paper}>
          <Typography component="h1" variant="h5">新規登録</Typography>
            <form className={styles.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="メールアドレス"
              name="email"
              autoComplete="email"
              onChange={e=>setEmail(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              onChange={e=>setPassword(e.target.value)}
              autoComplete="current-password"
              onKeyDown={e=>{
                if(e.keyCode === 13) {
                  handleSignUp();
                }
              }}
            />
            <Typography className={styles.errorMessage}>{errorMessage}</Typography>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={styles.submit}
              onClick={()=>handleSignUp()}
            >
              新規登録
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/login" variant="body2">
                  アカウントをお持ちの方はこちらからログイン
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
      }
    </BlankLayout>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3,0,2)
  },
  errorMessage: {
    color: "red"
  }
}));