import {makeStyles, Typography} from '@material-ui/core'
import MainLayout from 'kinu/layouts/main'
import KinuModelForm from 'kinu/components/ModelForm'
import {useModelContext} from 'context/model'
import {useAuthContext} from 'context/auth'
import {useSystemContext} from 'context/system'
import {useRef, useEffect, useState} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {clone} from 'kinu/plugins/functions'
import {db} from 'plugins/firebase'

export default function KinuCreatePage(props) {

  const styles = useStyles();

  const {createItem, editItem, getItem, getItemProp} = useModelContext();
  const {showLoading, hideLoading, showSnackbar} = useSystemContext();
  const {userId} = useAuthContext();

  const params = useParams();
  const history = useHistory();
  const modelForm = useRef(null);

  console.log("path", params.userId);
  console.log("path", params.childId);

  //モデルのselect items部分の書き換え
  let model = clone(props.model);
  model.columns.childPath.fsItems = 
    db.collectionGroup('children').where('officeId', '==', userId);
  

  const handleSend = data => {
    switch(props.mode) {
      case 'edit':
        handleEdit(data);
        break;
      case 'create':
      default:
        handleCreate(data);
        break;
    }
  }

  //新規作成
  const handleCreate = async (data) => {
    showLoading();
    console.log(data);
    const path = data.childPath + '/notes'
    const createRes = await createItem(props.model, data, path);
    if(createRes !== true) {
      modelForm.current.setErrors(createRes);
    }
    else {
      showSnackbar('登録しました');
      if(!!props.redirect) {
        history.push(props.redirect)
      }
    }
    hideLoading();
    return;
  }

  //編集
  const handleEdit = async (data) => {
    showLoading();
    const editRes = await editItem(props.model, data);
    if(editRes !== true) {
      modelForm.current.setErrors(editRes);
    } else {
      showSnackbar('編集しました');
      if(!!props.redirect) {
        history.push(props.redirect)
      }
    }
    hideLoading();
    return;
  }

  //コンポーネント作成時に実行
  useEffect(async ()=>{
    //編集の時
    if(props.mode === 'edit') {
      showLoading();
      const itemData = await getItem(props.model, params.id);
      modelForm.current.setData(itemData);
      hideLoading();
    }
    //新規の時
    else {
      if(!!params.userId && !!params.childId) {
        const childPath = 'users/'+params.userId+'/children/'+params.childId;
        modelForm.current.setData({
          childPath
        });
      }
    }
  },[]);

  return (
    <MainLayout>
      <Typography component="h1" variant="h5" className={styles.header}>
        {props.model.name}{props.mode === 'edit'? '編集' : '新規追加'}
      </Typography>
      <KinuModelForm
        model={model}
        mode={props.mode}
        send={handleSend}
        ref={modelForm}
      />
    </MainLayout>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2)
  }
}));