import {makeStyles, TextField} from '@material-ui/core'
import {useSystemContext} from 'context/system'

export default function KinuTextAreaForm({
  column,
  columnKey,
  data,
  setData,
  errors,
  setErrors,
  disabled,
}) {
  const styles = useStyles();
  const {rulesValidator} = useSystemContext();

  const handleChange = e => {
    setData({
      ...data,
      ...{[columnKey]: e.target.value}
    })

    const validatiorRes = rulesValidator(e.target.value, column.rules);
    const newError = validatiorRes === true? false : validatiorRes;
    setErrors({
      ...errors,
      ...{[columnKey]: newError}
    });
  }

  return (
    <div className={styles.container}>
      <TextField
        multiline
        rows={column.rows || 6}
        variant="outlined"
        margin="normal"
        required={column.required}
        fullWidth
        id={columnKey}
        label={column.label}
        name={columnKey}
        onChange={handleChange}
        error={!!errors[columnKey]}
        helperText={errors[columnKey]}
        placeholder={column.placeholder}
        value={data[columnKey]}
        disabled={disabled}
        //autoFocus
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2)
  }
}));