import admin from './sidebarData/admin';
import office from './sidebarData/office';

//サイドバー幅
export const sidebarWidth = 280;

export const sidebarItems = {
  admin,
  office
}
