import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

import {SystemProvider} from "context/system"
import {AuthProvider} from 'context/auth'
import {ModelProvider} from 'context/model'
import {UserProvider} from 'context/user'
import Loading from 'kinu/components/Loading'
import Snackbar from 'kinu/components/Snackbar'
import AuthRoute from 'kinu/auth/AuthRoute'

export default function App(props) {

  //const { window } = props;
  //const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <BrowserRouter>
      <SystemProvider>
        <AuthProvider>
          <ModelProvider>
            <UserProvider>
              <CssBaseline />
              <Loading />
              <Snackbar />
              <AuthRoute />
            </UserProvider>
          </ModelProvider>
        </AuthProvider>
      </SystemProvider>
    </BrowserRouter>
  );
}
