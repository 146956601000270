import KinuCreatePage from 'kinu/pages/ModelCreateEdit'
import ScheduleModel from 'models/Schedule'
import {useAuthContext} from 'context/auth'

export default function ScheduleCreate() {

  const {userId} = useAuthContext();

  return (
    <KinuCreatePage
      model={ScheduleModel}
      redirect="/office/schedule/list"
      path={`users/${userId}/schedules`}
    />
  )
}
