import {makeStyles, Typography, Card, CardContent} from '@material-ui/core'

export default function KinuCard(props) {
  const styles = useStyles();

  return (
    <Card className={styles.card}>
      <CardContent>
        {props.title? (
          <Typography className={styles.title}>{props.title}</Typography>
        ) : null}
        <div className={styles.content}>
          {props.children}
        </div>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    borderLeft: 6,
    borderLeftColor: theme.palette.primary.main,
    borderLeftStyle: "solid",
    paddingLeft: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(1)
  },
  card: {
    marginBottom: theme.spacing(3),
    position: "relative"
  }
}));