/*
 * ユーザー用モデル
 */

import rules from 'kinu/plugins/rules'
import {db} from 'plugins/firebase'
import imageSet from 'config/imageSet'

export default {

  //管理者
  admin: {
    name: "管理者",
    emailRules: [rules.required],
    passwordRules: [rules.moreThan(8)],
    //canSignup: false
    columns: {
      name: {
        label: "管理者名",
        type: "text",
        required: true,
        placeholder: "北谷太郎",
        rules: [rules.required, rules.moreThan(4)],
        headerWidth: 200,
        //hideEdit: true,
        //hideCreate: true,
        //disabledEdit: true,
        //showTypesForCreate: ['office'],
        //showTypesForEdit: ['office'],
        //disabledEditTypes: ['office']
      },
      gender: {
        label: "性別",
        type: "radio",
        items: ['男性','女性','その他'],
        required: true,
        //disabledEdit: true
        //rules: [rules.required]
      },
      agree: {
        label: "利用規約に同意します",
        type: "checkbox",
        required: true,
        hideHeader: true,
        disabledEdit: true,
        rules: [rules.mustCheck]
      }
    }
  },

  //事業所
  office: {
    name: "事業所",
    emailRules: [rules.required],
    passwordRules: [rules.required, rules.moreThan(8)],
    canSignUp: true,
    columns: {
      name: {
        label: "事業所名",
        type: "text",
        required: true,
        rules: [rules.required],
        showTypesForCreate: ['admin'],
        disabledEditTypes: ['office'],
        headerWidth: 240
      },
      officeId: {
        label: "検索用ID",
        type: "text",
        required: true,
        rules: [rules.required, rules.moreThan(4), rules.lessThan(12), rules.hwc],
        lazyRules: [rules.duplicate(db.collection('users'), 'officeId')],
        showTypesForCreate: ['admin'],
        disabledEditTypes: ['office']
      },
      introduction: {
        label: "事業所紹介文",
        type: "textarea",
        rules: [rules.lessThan(300)],
        rows: 6
      },
      address: {
        label: "住所",
        type: "address",
        required: true,
        rules: [rules.required]
      },
      tel: {
        label: "電話番号",
        type: "tel",
        required: true,
        rules: [rules.required]
      },
      image: {
        label: "事業所写真",
        type: "image",
        multiple: false,
        imageName: "officeImage",
        imageSet: imageSet.profile,
      }
    }
  },

  //ユーザー
  user: {
    name: "保護者",
    emailRules: [rules.required],
    passwordRules: [rules.required, rules.moreThan(8)],
    canSignUp: false,
    columns:{
      name: {
        label: "保護者名（漢字）",
        type: "text",
        required: true,
        rules: [rules.required]
      },
      kana: {
        label: "保護者名（ふりがな）",
        type: "text",
        required: true,
        rules: [rules.required]
      },
      tel: {
        label: "電話番号",
        type: "text",
        required: true,
        rules: [rules.required]
      },
      
    }
  }

}