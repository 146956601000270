import {FormControl, InputLabel, makeStyles, Select, Grid, MenuItem, Typography} from '@material-ui/core'
import {useSystemContext} from 'context/system'
import {getDateFromStr} from 'kinu/plugins/functions'

export default function KinuDateForm({
  column,
  columnKey,
  data,
  setData,
  errors,
  setErrors,
  disabled,
}) {
  const styles = useStyles();
  const {rulesValidator} = useSystemContext();

  const startDate = "1920-01-01";
  const endDate = "2022-12-31"

  const yearItems = () => {
    const start = Number(getDateFromStr(startDate, 'year'));
    const end = Number(getDateFromStr(endDate, 'year'));
    let res = [];
    for(let year = start; year <= end; year++) {
      res.push(
        <MenuItem value={year} key={year}>{year}</MenuItem>
      )
    }
    return res;
  }

  const monthItems = () => {
    let res = [];
    for(let month = 1; month <= 12; month++) {
      res.push(
        <MenuItem value={month} key={month}>{month}</MenuItem>
      )
    }
    return res;
  }

  const dayItems = () => {
    let res = [];
    for(let day = 1; day <= 31; day++) {
      res.push(
        <MenuItem value={day} key={day}>{day}</MenuItem>
      )
    }
    return res;
  }

  const handleChangeYear = e => {
    const year = e.target.value;
    const date = {
      ...data[columnKey],
      year,
    }
    handleChange(date);
  }

  const handleChangeMonth = e => {
    const month = e.target.value;
    const date = {
      ...data[columnKey],
      month,
    }
    handleChange(date);
  }

  const handleChangeDay = e => {
    const day = e.target.value;
    const date = {
      ...data[columnKey],
      day,
    }
    handleChange(date);
  }

  const handleChange = date => {
    setData({
      ...data,
      ...{[columnKey]: date}
    })

    const validatiorRes = rulesValidator(date, column.rules);
    const newError = validatiorRes === true? false : validatiorRes;
    setErrors({
      ...errors,
      ...{[columnKey]: newError}
    });
  }

  return (
    <div className={styles.container}>
      <Typography>{column.label}</Typography>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            variant="outlined"
            disabled={!!disabled}
            error={!!errors[columnKey]}
            required={column.required}
          >
            <InputLabel>年</InputLabel>
            <Select
              label="年"
              value={!!data[columnKey] ? Number(data[columnKey].year) : ""}
              onChange={handleChangeYear}
            >
              {yearItems()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            variant="outlined"
            disabled={!!disabled}
            error={!!errors[columnKey]}
            required={column.required}
          >
            <InputLabel>月</InputLabel>
            <Select
              label="月"
              value={!!data[columnKey] ? Number(data[columnKey].month) : ""}
              onChange={handleChangeMonth}
            >
              {monthItems()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            variant="outlined"
            disabled={!!disabled}
            error={!!errors[columnKey]}
            required={column.required}
          >
            <InputLabel>日</InputLabel>
            <Select
              label="日"
              value={!!data[columnKey] ? Number(data[columnKey].day) : ""}
              onChange={handleChangeDay}
            >
              {dayItems()}
            </Select>
          </FormControl>
        </Grid>
        <Typography className={styles.error}>{errors[columnKey]}</Typography>
      </Grid>
      {/* <TextField
        variant="outlined"
        margin="normal"
        required={column.required}
        fullWidth
        id={columnKey}
        label={column.label}
        name={columnKey}
        onChange={handleChange}
        error={!!errors[columnKey]}
        helperText={errors[columnKey]}
        placeholder={column.placeholder}
        value={data[columnKey]}
        disabled={disabled}
        //autoFocus
      /> */}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2)
  },
  error: {
    color: "red",
    fontSize: 12,
    paddingLeft: theme.spacing(3)
  }
}));