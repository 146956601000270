import KinuEditPage from 'kinu/pages/UserCreateEdit'
import {useParams} from 'react-router-dom'

export default function TestEdit() {
  const params = useParams();

  return (
    <KinuEditPage
      mode="edit"
      userId={params.id}
      redirect="/admin/office/list"
    />
  )
}
