import {makeStyles, Typography} from '@material-ui/core'
import Image from 'material-ui-image'
import { useEffect, useState } from 'react';
import {storage} from 'plugins/firebase'

export default function KinuImage(props) {
  const styles = useStyles();

  const [src, setSrc] = useState(props.src);

  useEffect(async ()=>{
    if(!!props.image.url) {
      setSrc(props.image.url);
    } else if(!!props.image.path) {
      const url = await storage.ref().child(props.image.path).getDownloadURL();
      setSrc(url);
    }
  },[props.image])

  return (
    <Image
      src={src}
      cover={props.cover}
      animationDuration={0}
      aspectRatio={props.aspectRatio || 1}
    />
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));