import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React from 'react';

class KinuDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      dialogResolve: null,
      title: "",
      content: "",
      maxWidth: "sm",
      single: false,
      reverse: false,
      trueText: "OK",
      falseText: "キャンセル"
    }
  }

  //表示
  show = (title, content, options = {}) => new Promise((resolve, reject) => {
    this.setState(prevState => {
      const updateState = {
        ...prevState,
        open: true,
        title,
        content,
        trueText: options.trueText || 'OK',
        falseText: options.falseText || 'キャンセル',
        maxWidth: options.maxWidth || "sm",
        single: !!options.single || false,
        reverse: !!options.reverse || false,
        dialogResolve: resolve
      }
      return  updateState;
    })
  })

  //キャンセル
  _handleCancel = () => {
    this.state.dialogResolve(false);
    this.setState({
      open: false,
    })
  }

  //OK
  _handleTrue = () => {
    this.state.dialogResolve(true);
    this.setState({
      open: false,
    })
  }

  render() {

    const trueButton = (
      <Button
        key="trueButton"
        onClick={this._handleTrue}
      >{this.state.trueText}</Button>
    );
    const falseButton = (
      <Button
        key="falseButton"
        onClick={this._handleCancel}
      >{this.state.falseText}</Button>
    );

    let buttons = [];
    //single
    if(this.state.single) {
      buttons.push(trueButton);
    }

    else {
      if(this.state.reverse) {
        buttons.push(trueButton);
        buttons.push(falseButton);
      }
      else {
        buttons.push(falseButton);
        buttons.push(trueButton);
      }
    }
    


    return (
      <Dialog
        open={this.state.open}
        maxWidth={this.state.maxWidth}
        fullWidth
      >
        <DialogTitle color="primary">{this.state.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.content}
          </DialogContentText>
          <DialogActions>
            {buttons}
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }
}
export default KinuDialog;