import {Typography} from '@material-ui/core'
import { ChildCare } from '@material-ui/icons'

export default function TimeToStr(props) {

  const time = props.children;
  const split = props.split || "-";
  const noTime = !!props.noTime

  const timeStr = (!props.children)? "" : timeToStr(time, split, noTime);

  return (
    <>
      {timeStr}
    </>
  )
}

const timeToStr = (time, split = "-", noTime = false) => {
  const d = new Date(Number(time) * 1000);
  const year = d.getFullYear();
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const day = ("0" + d.getDate()).slice(-2);
  const hour = ("0" + d.getHours()).slice(-2);
  const min = ("0" + d.getMinutes()).slice(-2);
  const sec = ("0" + d.getSeconds()).slice(-2);
  let res = year + split + month + split + day;
  if (!noTime) {
    res += " " + hour + ":" + min + ":" + sec;
  }
  return res;
};