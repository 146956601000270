import rules from 'kinu/plugins/rules'
import {db} from 'plugins/firebase'
import imageSet from 'config/imageSet'

export default {
  name: "保育ノート",
  path: "",
  columns: {
    childPath: {
      label: "園児",
      type: "select",
      required: true,
      rules: [rules.required],
      //fsItems: コンポーネントで書き換え
      cacheKey: 'childrenList',  //キャッシュ名 なければ強制取得
      itemValue: 'path',
      itemName: 'name',
      disabledEdit: true
    },
    title: {
      label: "タイトル",
      type: "text",
      required: true,
      rules: [rules.required],
    },
    content: {
      label: "保育ノート内容",
      type: "textarea",
      rows: 10,
      rules: [rules.required],
      required: true,
    },
    date: {
      label: "記入日",
      type: "date",
      required: true,
      rules: [rules.required]
    }
  }
}