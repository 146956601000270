import {
  Grid,
  makeStyles,
  Typography, 
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Paper
} from '@material-ui/core'
import MainLayout from '../../kinu/layouts/main'
import { useSystemContext } from "../../context/system";
import { useAuthContext } from '../../context/auth'
import ObjectDump from '../../develop/ObjectDump'
import KinuDialog from 'kinu/components/Dialog';
import {useRef} from 'react'
import Card from 'kinu/components/Card'

export default function AdminDashboard() {
  const styles = useStyles();
  const {showLoading, showSnackbar} = useSystemContext();
  const {userId, userType, userData, userSetting} = useAuthContext();


  const kinuDialog = useRef(null);

  return (
    <MainLayout>
      <div className={styles.container}>
        <Typography className={styles.title}>管理者ダッシュボード</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>

            <Card title="事業所サマリー">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>登録事業所数</TableCell>
                    <TableCell>{userSetting? userSetting.officeCount : "-"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
            
          </Grid>
        </Grid>
      </div>
    </MainLayout>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 'bold'
  }
}));