import {makeStyles, Typography} from '@material-ui/core'

export default function Top() {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Typography>トップページ</Typography>
      <Typography>誰でもアクセス可能</Typography>
      <a href="/admin">管理画面</a>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));