import {
  makeStyles,
  Snackbar,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import {useSystemContext} from '../../context/system'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ComponentName() {
  const styles = useStyles();
  const { snackbar, hideSnackbar, snackbarOptions } = useSystemContext();
  const { message, type, time } = snackbarOptions;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    hideSnackbar();
  }

  return (
    <div className={styles.container}>
      <Snackbar
        open={snackbar}
        autoHideDuration={time}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));