import DashboardIcon from '@material-ui/icons/Dashboard';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ChatIcon from '@material-ui/icons/Chat';
import InfoIcon from '@material-ui/icons/Info';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import ScheduleIcon from '@material-ui/icons/Schedule';
import NoteIcon from '@material-ui/icons/Note';

export default [
  {
    title: "事業所トップ",
    icon: <DashboardIcon />,
    to: "/office/"
  },
  {
    title: "管理者からのお知らせ",
    icon: <InfoIcon />,
    to: "/office/informations"
  },
  {
    divider: true
  },
  {
    header: "園児管理"
  },
  {
    title: "チャット",
    icon: <ChatIcon />,
    to: "/office/chat"
  },
  {
    title: "園児一覧",
    icon: <ListIcon />,
    to: "/office/user/list"
  },
  {
    title: "園児追加リクエスト",
    icon: <AddIcon />,
    to: "/office/user/request",
    id: "childRequest"
  },
  {
    divider: true
  },
  {
    header: "保育ノート管理"
  },
  {
    title: "保育ノート一覧",
    icon: <NoteIcon />,
    to: "/office/note/list"
  },
  {
    title: "保育ノート記入",
    icon: <AddIcon />,
    to: "/office/note/create"
  },
  {
    divider: true
  },
  {
    header: "お知らせ管理"
  },
  {
    title: "お知らせ一覧",
    icon: <InfoIcon />,
    to: "/office/info/list"
  },
  {
    title: "お知らせ追加",
    icon: <AddIcon />,
    to: "/office/info/create"
  },
  {
    divider: true
  },
  {
    header: "アルバム管理"
  },
  {
    title: "アルバム一覧",
    icon: <PhotoAlbumIcon />,
    to: "/office/album/list"
  },
  {
    title: "アルバム追加",
    icon: <AddIcon />,
    to: "/office/album/create"
  },
  {
    divider: true
  },
  {
    header: "スケジュール管理"
  },
  {
    title: "スケジュール一覧",
    icon: <ScheduleIcon />,
    to: "/office/schedule/list"
  },
  {
    title: "スケジュール追加",
    icon: <AddIcon />,
    to: "/office/schedule/create"
  },
  // {
  //   divider: true
  // },
  // {
  //   header: "請求書管理"
  // },
  // {
  //   title: "請求書一覧",
  //   icon: <ListIcon />,
  //   to: "/office/invoice/list"
  // },
  {
    divider: true
  },
  {
    header: "事業所設定"
  },
  {
    title: "事業所情報",
    icon: <SettingsIcon />,
    to: "/office/profile"
  },
  {
    title: "メールアドレス変更",
    icon: <EmailIcon />,
    to: "/changeEmail"
  },
  {
    title: "パスワード変更",
    icon: <VpnKeyIcon />,
    to: "/changePassword"
  },
  
]