import rules from 'kinu/plugins/rules'
import {db} from 'plugins/firebase'
import imageSet from 'config/imageSet'

export default {
  name: "アルバム",
  path: "",
  columns: {
    title: {
      label: "アルバムタイトル",
      type: "text",
      required: true,
      rules: [rules.required, rules.lessThan(64)],
      headerWidth: 220,
    },
    comment: {
      label: "コメント",
      type: "textarea",
      rows: 6,
      rules: [rules.required],
      required: true,
    },
    image: {
      label: "画像",
      type: "image",
      multiple: true,
      imageName: "album",
      imageSet: imageSet.album,
    },
  }
}