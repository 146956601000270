import {makeStyles, TextField, Typography} from '@material-ui/core'
import MainLayout from 'kinu/layouts/main'
import KinuModelForm from 'kinu/components/ModelForm'
import {useUserContext} from 'context/user'
import {useModelContext} from 'context/model'
import {useSystemContext} from 'context/system'
import {useRef, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import userModel from 'models/Users'

export default function KinuUserCreatePage(props) {

  const styles = useStyles();

  const {createUser, getUser, editUser} = useUserContext();
  const {showLoading, hideLoading, showSnackbar, rulesValidator} = useSystemContext();
  const {compareEditData} = useModelContext();

  const history = useHistory();
  const modelForm = useRef(null);

  const [userType, setUserType] = useState(props.type);
  const [model, setModel] = useState(userModel[userType]);
  const [originalData, setOriginalData] = useState({});

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleSend = data => {
    const userData = {
      ...data,
      email,
      password
    }
    if(props.test) {
      console.log(userData);
      return;
    }
    switch(props.mode) {
      case 'edit':
        handleEdit(userData);
        break;
      case 'create':
      default:
        handleCreate(userData);
        break;
    }
  }

  //エラーセット時
  const setUserErrors = errors => {
    if(!!errors.email) {
      setEmailError(errors.email);
    }
    if(!!errors.password) {
      setPasswordError(errors.password);
    }
    delete errors.email;
    delete errors.password;
    modelForm.current.setErrors(errors);
  }

  //メールアドレス変更時
  const handleChangeEmail = e => {
    setEmail(e.target.value);
    const emailError = rulesValidator(e.target.value, model.emailRules);
    setEmailError(emailError === true? false : emailError);
  }
  //パスワード変更時
  const handleChangePassword = e => {
    setPassword(e.target.value);
    const passwordError = rulesValidator(e.target.value, model.passwordRules);
    setPasswordError(passwordError === true? false : passwordError);
  }
  

  //新規作成
  const handleCreate = async (data) => {
    showLoading();
    const createRes = await createUser(userType, data);
    if(createRes !== true) {
      setUserErrors(createRes);
    }
    else {
      showSnackbar('登録しました');
      if(!!props.redirect) {
        history.push(props.redirect)
      }
    }
    hideLoading();
    return;
  }

  //編集
  const handleEdit = async (data) => {
    console.log(data);
    showLoading();

    const editData = compareEditData(data,originalData);
    console.log(editData);
    const editRes = await editUser(userType, editData);
    if(editRes !== true) {
      setUserErrors(editRes);
    } else {
      showSnackbar('編集しました');
      if(!!props.redirect) {
        history.push(props.redirect)
      }
    }
    hideLoading();
    return;
  }

  //コンポーネント作成時に実行
  useEffect(async ()=>{
    //編集の時
    if(props.mode === 'edit') {
      showLoading();
      const userData = await getUser(props.userId);
      setModel(userModel[userData.type]);
      setUserType(userData.type)
      setEmail(userData.email)
      modelForm.current.setData(userData);
      setOriginalData(userData);
      hideLoading();
    }
  },[]);

  const resJsx = !model ?
  (
    <MainLayout>Loading</MainLayout>
  ) :
  (
    <MainLayout>
      <Typography component="h1" variant="h5" className={styles.spaceBottom}>
        {model.name}{props.mode === 'edit'? '編集' : '新規追加'}
      </Typography>

      <Typography component="h2" variant="h6" className={styles.spaceBottom}>
        ログイン情報
      </Typography>
      <TextField
        label="ログイン用メールアドレス"
        className={styles.spaceBottom}
        fullWidth
        variant="outlined"
        required
        value={email}
        error={!!emailError}
        helperText={emailError}
        onChange={handleChangeEmail}
        disabled={props.mode === 'edit'}
      />
      {props.mode === 'edit' ||
        <TextField
          label="ログイン用パスワード"
          className={styles.spaceBottom}
          fullWidth
          variant="outlined"
          type="password"
          required
          value={password}
          error={!!passwordError}
          helperText={passwordError}
          onChange={handleChangePassword}
        />
      }

      <Typography component="h2" variant="h6">
        {model.name}情報
      </Typography>
      <KinuModelForm
        model={model}
        mode={props.mode}
        send={handleSend}
        ref={modelForm}
      />
    </MainLayout>
  )

  return resJsx
}

const useStyles = makeStyles(theme => ({
  spaceBottom: {
    marginBottom: theme.spacing(2)
  }
}));