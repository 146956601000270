import {makeStyles, Typography} from '@material-ui/core'
import MainLayout from 'kinu/layouts/main'
import KinuList from 'kinu/components/List'
import {useModelContext} from 'context/model'
import {useSystemContext} from 'context/system'
import KinuDialog from 'kinu/components/Dialog'
import {useRef, forwardRef, useImperativeHandle} from 'react'
import {useHistory} from 'react-router-dom'

export default forwardRef(function KinuListPage(props, ref) {
  const {deleteItem} = useModelContext();
  const {showLoading, hideLoading, showSnackbar} = useSystemContext();

  const deleteDialog = useRef(null);
  const kinuList = useRef(null);
  const history = useHistory();

  //親からのデータ更新
  useImperativeHandle(ref, () => {
    return {
      updateList: () => updateList()
    }
  })


  //削除
  const handleDelete = props.delete? async item => {
    const dialogRes = await deleteDialog.current.show(
      '削除確認',
      `ID:${item.id} を削除してもよろしいですか？`
    )
    if(dialogRes === false) return false;

    showLoading();
    await deleteItem(item.path, props.model);
    hideLoading();
    showSnackbar("削除完了しました");

    updateList();

    return true;
  } : false;

  //編集
  const handleEdit = props.edit? item => {
    const depth = props.editUrlDepth || item.editPath.length;
    let editUrl = props.edit;
    for(let i = 0; i < depth; i++) {
      editUrl += item.editPath[i] + '/';
    }
    history.push(editUrl);
  } : false;

  const updateList = () => {
    kinuList.current.updateList();
  }

  return (
    <MainLayout>
      <Typography component="h1" variant="h5">{props.model.name}一覧</Typography>
      <KinuList
        collection={props.collection}
        path={props.path}
        model={props.model}
        columns={props.columns}
        delete={handleDelete}
        edit={handleEdit}
        ref={kinuList}
        createAt={props.createAt}
        updateAt={props.updateAt}
      />
      <KinuDialog ref={deleteDialog}/>
    </MainLayout>
  )
})

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));