import KinuListPage from 'kinu/pages/ModelList'
import InfoModel from 'models/Info'
import {db} from 'plugins/firebase'
import { Button } from '@material-ui/core'
import {useAuthContext} from 'context/auth'

export default function TestList() {

  const {userId} = useAuthContext();
  const collection = db.collection('users').doc(userId).collection('infos');
  const columns = [
    {
      field: "officeName",
      headerName: "請求先",
      width: 170
    },
    {
      field: "title",
      headerName: "請求名",
      width: 200
    },
    {
      field: "price",
      headerName: "金額"
    },
    {
      field: "check",
      headerName: "支払い",
      renderCell: params => (
        <>
          {!!params.value? "済" : (
            <Button>未払い</Button>
          )}
        </>
      )
    }
  ]

  return (
    <KinuListPage
      model={InfoModel}
      collection={collection}
      delete  //削除機能をつけるかどうか
      edit="/office/info/edit/"  //編集ボタンのリンク先（これにID追加される）
      editUrlDepth={1}
      //columns={columns} //表示カラムを自分で指定
      createAt  //作成日時を表示するか
      //updateAt  //編集日時を表示するか
    />
  )
}
