import {makeStyles, Typography, Button, Grid} from '@material-ui/core'
import MainLayout from 'kinu/layouts/main'
import ChildrenList from './parts/childrenList'
import ChildNoteList from './parts/childNoteList'
import {db} from 'plugins/firebase'
import { useState } from 'react'
import {useSystemContext} from 'context/system'
import { useHistory } from 'react-router-dom'

export default function NoteList() {
  const styles = useStyles();
  const [notes, setNotes] = useState([]);
  const [selectChild, setSelectChild] = useState(null);
  const {cacheGet} = useSystemContext();

  const history = useHistory();

  const changeChild = async child => {
    setSelectChild(child);
    const path = child.path;
    await updateNotes(path);
    // const ref = db.collection(path + '/notes').orderBy('createAt', 'desc');
    // const key = "note" + path;
    // const getRes = await cacheGet(ref, key);
    // console.log(getRes);
    // if(!getRes.empty) {
    //   setNotes(getRes.data);
    // }
    // else {
    //   setNotes([]);
    // }
  }

  const updateNotes = async path => {
    if(!path) path = selectChild.path;
    const ref = db.collection(path + '/notes').orderBy('createAt', 'desc');
    const key = "note" + path;
    const getRes = await cacheGet(ref, key);
    if(!getRes.empty) {
      setNotes(getRes.data);
    }
    else {
      setNotes([]);
    }
  }

  return (
    <MainLayout>
      <Typography className={styles.title}>保育ノート一覧</Typography>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <ChildrenList
            select={changeChild}
          />
        </Grid>
        <Grid item xs={9}>
          {!!selectChild ? (
            <>
              <Typography className={styles.listTitle}>{selectChild.name}{selectChild.gender == 'boy' ? 'くん' : 'ちゃん'}の保育ノート</Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={()=>history.push('/office/note/create/'+selectChild.editPath[1]+'/'+selectChild.editPath[0])}
              >ノート追加</Button>
            </>
          ) : null}
          <ChildNoteList
            updateList={updateNotes}
            items={notes}
          />
        </Grid>
      </Grid>
    </MainLayout>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  listTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  }
}));