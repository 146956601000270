import React, {useState, useRef} from 'react'
import {
  makeStyles,
  Typography,
  Container,
  TextField,
  Button
} from '@material-ui/core'
import MainLayout from 'kinu/layouts/main'
import {useAuthContext} from 'context/auth'
import {useSystemContext} from 'context/system'
import ReAuthDialog from 'kinu/components/ReAuthDialog'

export default function ChangePassword() {
  const styles = useStyles();

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [error, setError] = useState("");
  const [lock, setLock] = useState(false);
  const {changePassword} = useAuthContext();
  const {showLoading, hideLoading, showSnackbar} = useSystemContext();

  //パスワード変更処理
  const doChange = async () => {
    if(lock) return false;

    //バリデーション
    const validationRes = validation();
    if(validationRes !== true) {
      setError(validationRes);
      return false;
    }

    showLoading();
    setLock(true);
    const res = await changePassword(password);
    if(res !== true) {
      switch(res) {
        case "auth/weak-password":
          setError("パスワードが短すぎます");
          break;
        case "auth/requires-recent-login":
          reAuth()
          break;
        default:
          setError("エラーが発生しました");
          break;
      }
      hideLoading();
      setLock(false)
      return false;
    }

    showSnackbar("パスワードを変更しました","success");
    setError("");
    setPassword("");
    setPasswordAgain("");
    setLock(false);
    hideLoading();
  }

  //パスワード変更時バリデーション
  const validation = () => {
    //文字数
    if(password.length < 8) {
      return "パスワードは8文字以上で設定して下さい"
    }


    //パスワード一致
    if(password !== passwordAgain) {
      return "パスワードが一致しません";
    }

    return true;
  }

  //再認証
  const reAuthDialog = useRef(null);
  const reAuth = async () => {
    const res = await reAuthDialog.current.show();
    if(!res) {
      return false;
    }

    //再度メールアドレス変更
    doChange();
  }

  return (
    <MainLayout>
      <Typography component="h1" variant="h5">パスワード変更</Typography>
      <form onSubmit={e=>{
        e.preventDefault();
      }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          label="新しいパスワード"
          name="password"
          autoComplete="password"
          onChange={e=>setPassword(e.target.value)}
          value={password}
          type="password"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="passwordAgain"
          label="パスワード再度入力"
          name="passwordAgain"
          autoComplete="passwordAgain"
          onChange={e=>setPasswordAgain(e.target.value)}
          value={passwordAgain}
          type="password"
          onKeyDown={e=>{
            if(e.keyCode === 13) {
              doChange();
            }
          }}
        />
        <Typography className={styles.errorMessage}>{error}</Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={doChange}
        >
          パスワード変更
        </Button>
      </form>
      <ReAuthDialog ref={reAuthDialog} />
    </MainLayout>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  errorMessage: {
    color: "red"
  }
}));