import DashboardIcon from '@material-ui/icons/Dashboard';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

export default [
  {
    title: "管理者トップ",
    icon: <DashboardIcon />,
    to: "/admin/"
  },
  {
    divider: true
  },
  {
    header: "事業所管理"
  },
  {
    title: "事業所一覧",
    icon: <ListIcon />,
    to: "/admin/office/list"
  },
  {
    title: "事業所追加",
    icon: <AddIcon />,
    to: "/admin/office/create"
  },
  {
    divider: true
  },
  // {
  //   header: "請求管理"
  // },
  // {
  //   title: "発行済請求一覧",
  //   icon: <ListIcon />,
  //   to: "/admin/invoice/list"
  // },
  // {
  //   title: "新規請求発行",
  //   icon: <AddIcon />,
  //   to: "/admin/invoice/create"
  // },
  // {
  //   title: "自動請求発行設定",
  //   icon: <SettingsIcon />,
  //   to: "/admin/invoice/setting"
  // },
  // {
  //   divider: true
  // },
  {
    header: "管理者お知らせ"
  },
  {
    title: "お知らせ一覧",
    icon: <ListIcon />,
    to: "/admin/infos/list"
  },
  {
    title: "お知らせ新規作成",
    icon: <AddIcon />,
    to: "/admin/infos/create"
  },
  {
    divider: true
  },
  {
    header: "設定"
  },
  {
    title: "システム設定",
    icon: <SettingsIcon />,
    to: "/admin/setting"
  },
  {
    title: "メールアドレス変更",
    icon: <EmailIcon />,
    to: "/changeEmail"
  },
  {
    title: "パスワード変更",
    icon: <VpnKeyIcon />,
    to: "/changePassword"
  },
  
]