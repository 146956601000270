import KinuListPage from 'kinu/pages/ModelList'
import UserModel from 'models/Users'
import {db} from 'plugins/firebase'

export default function OfficeList() {

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 200
    // },
    {
      field: "name",
      headerName:  "事業所名",
      width: 200
    },
    {
      field: "officeId",
      headerName: "検索用ID",
    },
    // {
    //   field: "type",
    //   headerName: "タイプ"
    // },
    {
      field: "email",
      headerName: "メールアドレス"
    }
  ]
  const collection = db.collection('users').where('type','==','office')

  return (
    <KinuListPage
      model={UserModel.office}
      collection={collection}
      delete  //削除機能をつけるかどうか
      edit="/admin/office/edit/"  //編集ボタンのリンク先（これにID追加される）
      columns={columns} //表示カラムを自分で指定
      createAt  //作成日時を表示するか
      updateAt  //編集日時を表示するか
    />
  )
}
