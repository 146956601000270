import React, { useContext, useState } from 'react'
import {fsGet, makeItemData} from 'kinu/plugins/functions'

//Context
const SystemContext = React.createContext();

//Provider
export const SystemProvider = ({children}) => {
  const [drawer, setDrawer] = useState(true);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState({});
  const [sidebarBadges, setSidebarBadges] = useState({childRequest:200});
  const [fsCache, setFsCache] = useState([]);

  //サイドバー関連
  const toggleDrawer = () => {
    setDrawer(!drawer);
  }
  const hideDrawer = () => {
    setDrawer(false);
  }
  const showDrawer = () => {
    setDrawer(true);
  }
  const setSidebarBadge = badgeData => {
    if(!badgeData) {
      setSidebarBadges({});
      return;
    }

    const saveData = {
      ...sidebarBadges,
      ...badgeData
    }
    setSidebarBadges(saveData);
    return;
  }
  

  //ローディング画面関連
  const showLoading = () => {
    setLoading(true);
  }
  const hideLoading = () => {
    setLoading(false);
  }

  //スナックバー関連
  const showSnackbar = (message, type = "success", time = 4000) => {
    setSnackbarOptions({
      message,
      type,
      time
    })
    setSnackbar(true);
  }
  const hideSnackbar = () => {
    setSnackbar(false);
  }

  //フォームバリデーター
  const rulesValidator = (value, rules) => {
    if(!rules) return true;

    for(let i = 0; i < rules.length; i++) {
      const validationRes = rules[i](value);
      if(validationRes !== true) {
        return validationRes;
      }
    }
    return true;
  }

  /* firebaseから最初はサーバーから取得、次回以降キャッシュから取得
   * ref: FSref
   * key: 識別キー（このキーが既にあればキャッシュから、なければサーバー）
   * force: Boolean trueの時、強制的にサーバーから
   */
  const cacheGet = async (ref, key = null, force = false) => {
    let server = false;
    if(force) {
      server = true;
    }
    else if(key) {
      server = (fsCache.indexOf(key) !== -1)? false : true;
      const newFsCache = [...fsCache, key];
      setFsCache(newFsCache);
    }
    const res = await fsGet(ref, server);

    let ret = {
      source: res.source
    }
    //collectionの時
    if('docs' in res.data) {
      ret.empty = res.data.empty;
      ret.size = res.data.size;
      let temp = [];
      for(let i = 0; i < res.data.docs.length; i++) {
        temp.push(makeItemData(res.data.docs[i]));
      }
      ret.data = temp;
    } 

    //docの時
    else {
      //
    }

    return ret;
  }
  const cacheReset = () => {
    setFsCache([]);
  }

  //fsCacheGet :後々使わないようにしたい
  const fsCacheGet = async db => {
    const res = await db.get({source: "cache"})
    .catch(async e=>{
      console.log("no cache")
      const res = await db.get()
      return res;
    })
    return res;
  }

  return(
    <SystemContext.Provider value={{
      drawer,
      toggleDrawer,
      showDrawer,
      hideDrawer,
      sidebarBadges,
      setSidebarBadge,
      loading,
      showLoading,
      hideLoading,
      snackbar,
      showSnackbar,
      hideSnackbar,
      snackbarOptions,
      rulesValidator,
      cacheGet,
      cacheReset,
      fsCacheGet
    }}>
      {children}
    </SystemContext.Provider>
  )
};

//useContext
export const useSystemContext = () => useContext(SystemContext);
