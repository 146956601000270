import {makeStyles, MenuItem, InputLabel, FormControl, FormLabel, RadioGroup, FormHelperText, Select} from '@material-ui/core'
import {useSystemContext} from 'context/system'
import { useEffect, useState } from 'react';
import {makeItemData} from 'kinu/plugins/functions'

export default function KinuRadioForm({
  columnKey,
  column,
  data,
  setData,
  errors,
  setErrors,
  disabled
}) {
  const styles = useStyles();
  const {rulesValidator, cacheGet} = useSystemContext();

  const handleChange = e => {
    setData({
      ...data,
      ...{[columnKey]: e.target.value}
    })

    const validatiorRes = rulesValidator(e.target.value, column.rules);
    const newError = validatiorRes === true ? false : validatiorRes;
    setErrors({
      ...errors,
      ...{[columnKey]: newError}
    });
  }

  const [selectItems, setSelectItems] = useState([]);
  const itemName = column.itemName || 'name';
  const itemValue = column.itemValue || 'value';

  useEffect(async ()=>{
    let tempItems = [];
    //firestoreからのリスト作成
    if(!!column.fsItems) {
      const key = column.cacheKey || null;
      const server = !column.cacheKey;
      const fsRes = await cacheGet(column.fsItems, key, server);
      console.log(fsRes);

      if(!fsRes.empty) {
        for(let i = 0; i < fsRes.data.length; i++) {

          const data = fsRes.data[i];
          tempItems.push(
            <MenuItem
              key={i}
              value={data[itemValue]}
            >{data[itemName]}</MenuItem>
          )
        }
      }
    }

    //配列からのリスト
    else {
      for(let i = 0; i < column.items.length; i++) {
        if(typeof column.items[i] === 'string') {
          tempItems.push(
            <MenuItem
              key={i}
              value={column.items[i]}
            >{column.items[i]}</MenuItem>
          )
        }
        else {
          tempItems.push(
            <MenuItem
              key={i}
              value={column.items[i][itemValue]}
            >{column.items[i][itemName]}</MenuItem>
          )
        }
      }
    }

    setSelectItems(tempItems);
  },[])

  return (
    <div className={styles.container}>
      <FormControl
        component="fieldset"
        error={!!errors[columnKey]}
        disabled={disabled}
        variant="outlined"
        fullWidth
      >
        <InputLabel id={'selectlabel'+columnKey}>{column.label}</InputLabel>
        <Select
          labelId={'selectlabel'+columnKey}
          label={column.label}
          id={'select'+columnKey}
          value={data[columnKey]}
          onChange={handleChange}
        >{selectItems}</Select>
        <FormHelperText>{errors[columnKey]}</FormHelperText>
      </FormControl>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2)
  }
}));