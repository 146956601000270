//画像の圧縮セット
//defaultは必須

const profile = {
  default: {
    quality: 0.8,
    maxWidth: 400,
    maxHeight: 400
  },
  small: {
    quality: 0.8,
    maxHeight: 200,
    maxWidth: 200
  }
}

const info = {
  default: {
    quality: 0.8,
    maxWidth: 1000,
    maxHeight: 1000
  }
}

const album = {
  default: {
    quality: 0.9,
    maxWidth: 1800,
    maxHeight: 1800
  },
  list: {
    quality: 0.8,
    maxWidth: 300,
    maxHeight: 300
  }
}

export default {
  profile,
  album,
  info
}