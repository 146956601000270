import {makeStyles, Typography} from '@material-ui/core'
import Card from './childNoteCard'
import KinuDialog from 'kinu/components/Dialog'
import { useRef } from 'react';
import {useSystemContext} from 'context/system'
import {useModelContext} from 'context/model'
import NoteModel from 'models/Note'
import { useHistory } from 'react-router-dom';

export default function ChildNoteList(props) {
  const styles = useStyles();
  const deleteDialog = useRef(null);
  const {showLoading, hideLoading, showSnackbar} = useSystemContext();
  const {deleteItem} = useModelContext();
  const history = useHistory();

  const handleEdit = item => {
    const url = `/office/note/edit/${item.editPath[2]}/${item.editPath[1]}/${item.editPath[0]}`;
    console.log(url)
    history.push(url);
  }

  //削除
  const handleDelete = async item => {
    const dialogRes = await deleteDialog.current.show(
      '削除確認',
      `「${item.title} 」を削除してもよろしいですか？`
    )
    if(dialogRes === false) return false;

    showLoading();
    await deleteItem(item.path, NoteModel);
    hideLoading();
    showSnackbar("削除完了しました");

    props.updateList();

    return true;
  }

  return (
    <div className={styles.container}>
      {props.items.map(item=>(
        <Card
          edit={handleEdit}
          delete={handleDelete}
          key={item.path}
          item={item}
        />
      ))}
      <KinuDialog ref={deleteDialog}/>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));