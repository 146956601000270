import _ from 'lodash'

/* 郵便番号を3桁と4桁に分ける
 * splitZipcode
 */
export const splitZipcode = zipcode => {
  const zip = zipcode.replace(/[^0-9]/g, "");
  if (zip.length !== 7) return false;
  let res = [];
  res.push(zip.slice(0, 3));
  res.push(zip.slice(-4));
  return res;
};

/* オブジェクトの配列で、特定のキーが引数と一致する単一オブジェクトを返す
 * 該当が複数ある場合は、一番最初に見つかったオブジェクトを返す
 *
 * list:Array 検索元の配列(list)
 * key:String 調べるキー
 * value:Any このvalueに一致するオブジェクトを返す
 * single:String このプロパティが指定されている時、該当するオブジェクトの中のsingleで指定したキーの値を返す
 */
export const searchList = (list, key, value, single = null) => {
  const res = list.filter(item => {
    return item[key] === value;
  });
  if (!res[0]) return false;

  if (!!single) {
    return res[0][single];
  }
  return res[0];
};

/* 日付文字列から年、月、日、時、分、秒を取得
 * YYYY-MM-DD H:i:d
 *
 */
export const getDateFromStr = (str, type = null) => {
  const datetime = str.split(" ");

  const date = datetime[0].split("-");
  const time = !!datetime[1]? datetime[1].split(":") : [];

  const res = {
    year: date[0],
    month: date[1],
    day: date[2],
    hour: time[0] || null,
    min: time[1] || null,
    sec: time[2] || null
  };

  if(!!type) {
    return res[type];
  }
  return res;
}

/* FSからデータの取得
 * キャッシュがある場合はキャッシュから、なければサーバーから
 * ref:FirestoreRef
 * server: Boolean trueの場合、強制的にサーバーから取得
 */
export const fsGet = async (ref, server = false) => {
  let res = null;
  if(server) {
    res = await fsGetImpl(ref, true);
  }
  else {
    try {
      res = await fsGetImpl(ref);
    }
    catch(e) {
      res = await fsGetImpl(ref, true);
    }
  }
  return res;
}
const fsGetImpl = async (ref, server = false) => {
  const source = server ? 'server' : 'cache';
  const res = await ref.get({source});
  const ret = {
    source,
    data: res
  }
  return ret;
}

/*
 * ディープコピー
 */
export const clone = obj => {
  return _.cloneDeep(obj);
}

/*
 * 自動的にFSから取得したデータに情報付与
 */
export const makeItemData = doc => {
  return {
    ...doc.data(),
    id: doc.id,
    path: doc.ref.path,
    editPath: makeEditPath(doc.ref)
  }
}
//編集時のリンク用の親のIDの配列
const makeEditPath = ref => {
  let ids = [ref.id];
  let checkRef = ref;
  while(!!checkRef.parent.parent) {
    checkRef = checkRef.parent.parent;
    ids.push(checkRef.id);
  }
  return ids;
}

/*
 * 改行コードを<br />に変換
 */
export const nl2br = text => {
  let res = text.replace(/\r\n/g, "<br />");
  res = text.replace(/(\n|\r)/g, "<br />");
  return res;
}