import {
  makeStyles,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Button,
} from '@material-ui/core'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu';
import {sidebarWidth} from '../../config/sidebar'
import {useSystemContext} from '../../context/system'
import {useAuthContext} from '../../context/auth'
import {withRouter} from 'react-router-dom'

export default withRouter(function Header({history}) {
  const styles = useStyles();
  const {toggleDrawer, drawer} = useSystemContext();
  const {logout} = useAuthContext();

  return (
    <AppBar
      position="absolute"
      className={clsx(styles.header, drawer && styles.headerShift)}
    >
      <Toolbar>
      <IconButton
        edge="start"
        color="inherit" 
        aria-label="menu" 
        className={styles.menuButton} 
        onClick={()=>{toggleDrawer()}}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" className={styles.title}>
        キッズコネクト
      </Typography>
      <Button color="inherit" onClick={()=>logout()}>Logout</Button>
      </Toolbar>
    </AppBar>
  )
})

const useStyles = makeStyles(theme => ({
  header: {
    width: "100%",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerShift: {
    marginLeft: sidebarWidth,
    width: `calc(100% - ${sidebarWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 36,
  }
}));