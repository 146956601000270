import KinuListPage from 'kinu/pages/ModelList'
import ChildModel from 'models/Child'
import {db} from 'plugins/firebase'
import { Button } from '@material-ui/core'
import {useAuthContext} from 'context/auth'
import {useModelContext} from 'context/model'
import {useSystemContext} from 'context/system'
import { useRef } from 'react'
import { SaveAlt } from '@material-ui/icons'

export default function RequestList() {

  const {userId} = useAuthContext();
  const {editItem} = useModelContext();
  const {showLoading, hideLoading} = useSystemContext();
  const kinuList = useRef(null);

  const updateList = () => {
    kinuList.current.updateList();
  }

  const collection = db.collectionGroup('children').where('officeId','==',userId).where('accept','==', false);
  const genderList = {
    boy: "男の子",
    girl: "女の子"
  }
  const columns = [
    {
      field: "name",
      headerName: "園児名",
      width: 140
    },
    {
      field: "kana",
      headerName: "ふりがな",
      width: 140
    },
    {
      field: "gender",
      headerName: "性別",
      renderCell: params =>(
        <>{genderList[params.value]}</>
      ),
      width: 100
    },
    {
      field: "birthday",
      headerName: "誕生日",
      renderCell: params => (
        <>{params.value.year}年{params.value.month}月{params.value.day}日</>
      ),
      width: 140
    },
    {
      field: "accept",
      headerName: "承認",
      renderCell: params => (
        <Button
          color="primary"
          variant="outlined"
          onClick={()=>acceptCreate(params.row.path)}
        >承認</Button>
      )
    }
  ]

  const acceptCreate = async path => {
    showLoading();
    const saveData = {
      path,
      accept: true
    }
    const res = await editItem(ChildModel, saveData)
    updateList();
    hideLoading();
  }

  return (
    <KinuListPage
      model={ChildModel}
      collection={collection}
      delete  //削除機能をつけるかどうか
      //edit="/office/info/edit/"  //編集ボタンのリンク先（これにID追加される）
      //editUrlDepth={1}
      columns={columns} //表示カラムを自分で指定
      createAt  //作成日時を表示するか
      //updateAt  //編集日時を表示するか
      ref={kinuList}
    />
  )
}
