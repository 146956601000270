import {Drawer, Divider,ListItemSecondaryAction, ListItem, List, ListItemIcon, ListItemText, Typography, makeStyles} from '@material-ui/core'
import {sidebarItems, sidebarWidth} from '../../config/sidebar';
import {useSystemContext} from '../../context/system'
import {withRouter} from 'react-router-dom'
import {useAuthContext} from '../../context/auth'

export default withRouter(function Sidebar(props){

  const styles = useStyles();
  const system = useSystemContext();
  const {userType} = useAuthContext();

  if(!userType) return <></>;

  /* サイドバーコンテンツの生成 */
  function DrawerContents(){

    var items = [];
    for(let i = 0; i < sidebarItems[userType].length; i++) {
      const item = sidebarItems[userType][i];

      if(item.divider) {
        items.push(
          <Divider key={i} />
        )
      }
      else if(item.header) {
        items.push(
          <Typography className={styles.header} key={i}>{item.header}</Typography>
        )
      }
      else {
        const badge = system.sidebarBadges[item.id] || null;
        items.push(
          <ListItem button key={i} onClick={()=>{props.history.push(sidebarItems[userType][i].to)}}>
            <ListItemIcon>{sidebarItems[userType][i].icon}</ListItemIcon>
            <ListItemText primary={sidebarItems[userType][i].title} className={styles.sidebarItem}/>
            {!!badge? (
              <div className={styles.badge}>{badge}</div>
            ): null}
          </ListItem>
        )
      }
    }

    return (
      <div>
        <Divider />
        <List>
          {items}
        </List>
      </div>
    )
  }

  /* サイドバーメイン */
  return (
    <div>
      <Drawer
        classes={{paper:styles.sidebar}}
        variant="persistent"
        open={system.drawer}
      >
        <DrawerContents />
      </Drawer>
    </div>
  )
})

const useStyles = makeStyles(theme => ({
  sidebar: {
    width: sidebarWidth,
    
  },
  sidebarItem: {
    fontSize: 14
  },
  header: {
    fontSize: 14,
    color: "#555555",
    fontWeight: "bold",
    paddingLeft: 16,
    paddingTop: 12,
    paddingBottom: 4
  },
  badge: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 'bold',
    //width: ,
    height: 24,
    lineHeight: '24px',
    textAlign: "center",
    borderRadius: "12px",
    padding: theme.spacing(0, 1)
  }
}))