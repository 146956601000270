import KinuCreatePage from 'kinu/pages/ModelCreateEdit'
import AlbumModel from 'models/Album'
import {useAuthContext} from 'context/auth'

export default function AlbumCreate() {

  const {userId} = useAuthContext();

  return (
    <KinuCreatePage
      model={AlbumModel}
      redirect="/office/album/list"
      path={`users/${userId}/albums`}
    />
  )
}
