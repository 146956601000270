import KinuUserCreatePage from 'kinu/pages/UserCreateEdit'

export default function TestCreate() {

  return (
    <KinuUserCreatePage
      type="office"
      redirect="/admin/office/list"
      //test
    />
  )
}
