import rules from 'kinu/plugins/rules'
import {db} from 'plugins/firebase'
import imageSet from 'config/imageSet'

export default {
  name: "管理者からのお知らせ",
  path: "infos",
  columns: {
    title: {
      label: "お知らせタイトル",
      type: "text",
      required: true,
      rules: [rules.required, rules.lessThan(64)],
      headerWidth: 220,
    },
    content: {
      label: "お知らせ内容",
      type: "textarea",
      rows: 10,
      rules: [rules.required],
      required: true,
    },
  }
}