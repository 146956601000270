import React from 'react'
import {
  makeStyles,
  Typography,
  Container,
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import BlankLayout from '../kinu/layouts/blank'
import { useAuthContext } from "../context/auth";
import { useSystemContext } from "../context/system"

import {Link} from 'react-router-dom'

export default function Login() {
  const styles = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const {login} = useAuthContext();
  const {showLoading, hideLoading} = useSystemContext();

  //ログイン処理
  const doLogin = async () => {
    showLoading();
    const loginRes = await login(email, password);

    //ログイン失敗
    if(loginRes !== true) {
      switch(loginRes) {
        case "auth/invalid-email":
          setErrorMessage("正しいメールアドレスを入力して下さい")
          break;
        default:
          setErrorMessage("メールアドレスまたはパスワードが間違っています");
          break
      }
      hideLoading();
    }
  }

  return (
    <BlankLayout>
      <Container maxWidth="xs">
      <div className={styles.paper}>
        <Typography component="h1" variant="h5">
          ログイン
        </Typography>
        <form className={styles.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            onChange={e=>setEmail(e.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            onChange={e=>setPassword(e.target.value)}
            autoComplete="current-password"
            onKeyDown={e=>{
              if(e.keyCode === 13) {
                doLogin();
              }
            }}
          />
          <Typography className={styles.errorMessage}>{errorMessage}</Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={styles.submit}
            onClick={()=>doLogin()}
          >
            ログイン
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/forgetPassword" variant="body2">
                パスワードを忘れた方はこちら
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      </Container>
    </BlankLayout>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3,0,2)
  },
  errorMessage: {
    color: "red"
  }
}));