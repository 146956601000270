import rules from 'kinu/plugins/rules'
import {db} from 'plugins/firebase'
import imageSet from 'config/imageSet'

export default {
  name: "園児",
  columns: {
    name: {
      label: "名前",
      type: "text",
      required: true,
      rules: [rules.required],
      headerWidth: 220,
    },
    kana: {
      label: "名前（ふりがな）",
      type: "text",
      rules: [rules.required],
      required: true,
    },
    birthday: {
      label: "誕生日",
      type: "date",
      required: true,
      rules: [rules.required]
    },
    gender: {
      label: "性別",
      type: "radio",
      items:[{
        key: "boy",
        value: "男の子"
      },{
        key: "girl",
        value: "女の子"
      }],
      required: true,
      rules: [rules.required]
    },
    image: {
      label: "写真",
      type: "image",
      multiple: false,
      imageName: "childProfile",
      imageSet: imageSet.profile,
    },
    accept: {
      label: "承認"
    },
    officeId: {
      label: "所属事業所ID",
      type: "text",
      hideCreate: true,
      disabledEdit: true,
    },
    officeName: {
      label: "所属事業所名",
      type: "text",
      hideCreate: true,
      disabledEdit: true
    }
  }
}