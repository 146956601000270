import {Button, makeStyles, TextField} from '@material-ui/core'
import MyChatItem from './myChatItem'
import OtherChatItem from './otherChatItem'
import {useAuthContext} from 'context/auth'
import { useState } from 'react';
import firebase, {db} from 'plugins/firebase'

export default function ChildChatList(props) {
  const styles = useStyles();
  const {userId} = useAuthContext();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const ChatRender = () => {
    return props.items.map(item=>{
      if(userId == item.userId) {
        return (
          <MyChatItem
            key={item.id}
            item={item}
          />
        )
      }
      return (
        <OtherChatItem
          key={item.id}
          item={item}
        />
      )
    })
  }

  const sendMessage = async () => {
    if(!message) return;

    setLoading(true);
    const res = await db.collection(`${props.path}/chat`).add({
      message,
      userId,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
      updateAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    setMessage("");
    props.updateList();
    setLoading(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.chatContainer}>
        <ChatRender />
      </div>
      <div className={styles.inputContainer}>
        <TextField
          className={styles.input}
          label="メッセージ"
          variant="outlined"
          multiline
          size="small"
          value={message}
          onChange={v=>setMessage(v.target.value)}
        />
        <Button
          className={styles.sendButton}
          variant="contained"
          color="primary"
          onClick={()=>sendMessage()}
          disabled={loading}
        >送信
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "#fff",
    height: 600,
    borderColor: "#ccc",
    borderWidth: 4,
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column"
  },
  chatContainer: {
    width: "100%",
    flex: 1,
    overflow: "scroll",
    display: "flex",
    flexDirection: "column-reverse",
    padding: theme.spacing(1, 2)
  },
  inputContainer: {
    width: "100%",
    flex: 0,
    flexShrink: 0,
    flexBasis: "auto",
    minHeight: 45,
    backgroundColor: "#ccc",
    padding: 4,
    display: "flex",
    flexDirection: "row"
  },
  input: {
    backgroundColor: "#fff",
    flex: 1
  },
  sendButton: {
    marginLeft: theme.spacing(1)
  }
}));