import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React from 'react';
import {withStyles} from '@material-ui/core/styles'
import {prefectures} from 'kinu/plugins/lists'
import {searchList} from 'kinu/plugins/functions'

class KinuZipDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      items: [],
      dialogResolve: null,
    }
  }

  //表示
  show = (items) => new Promise((resolve, reject) => {
    this.setState(prevState => {
      const updateState = {
        ...prevState,
        open: true,
        items: items,
        dialogResolve: resolve
      }
      return  updateState;
    })
  })

  //キャンセル
  _handleCancel = () => {
    this.state.dialogResolve(false);
    this.setState({
      open: false,
    })
  }

  //住所選択
  _handleClick = item => {
    this.state.dialogResolve(item);
    this.setState({
      open: false,
    })
  }

  render() {

    const { classes } = this.props;

    const falseButton = (
      <Button
        key="falseButton"
        onClick={this._handleCancel}
      >キャンセル</Button>
    );

    return (
      <Dialog
        open={this.state.open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle color="primary">該当住所が複数見つかりました</DialogTitle>
        <DialogContent>
          <DialogContentText>
            以下から住所を選択して下さい
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>都道府県</th>
                  <th>住所</th>
                  <th>選択</th>
                </tr>
              </thead>
              <tbody>
                {this.state.items.map((item, index)=>(
                  <tr key={index} className={classes.tr}>
                    <td>{searchList(prefectures, 'code', Number(item.prefcode), 'name') || ""}</td>
                    <td>{item.ja.address1 + item.ja.address2 + item.ja.address3 + item.ja.address4}</td>
                    <td>
                      <Button
                        variant="outlined"
                        onClick={()=>this._handleClick(item)}
                      >選択</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </DialogContentText>
          <DialogActions>
            {falseButton}
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }
}

const styles = theme => ({
  table: {
    padding: 4
  },
});

export default withStyles(styles, {withTheme: true})(KinuZipDialog);
