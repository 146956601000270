import KinuCreatePage from './ModelCreateEdit'
import NoteModel from 'models/Note'

export default function NoteCreate() {

  return (
    <KinuCreatePage
      model={NoteModel}
      redirect="/office/note/list"
    />
  )
}
