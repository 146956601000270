import {makeStyles, TextField, Typography, Button, FormControl, Select, InputLabel, MenuItem} from '@material-ui/core'
import {useSystemContext} from 'context/system'
import { useRef, useState } from 'react';
import {prefectures} from 'kinu/plugins/lists'
import {splitZipcode} from 'kinu/plugins/functions'
import axios from 'axios'
import KinuZipDialog from 'kinu/components/form/addressZipDialog'

export default function KinuAddressForm({
  column,
  columnKey,
  data,
  setData,
  errors,
  setErrors,
  disabled,
}) {
  const styles = useStyles();
  const {rulesValidator, showSnackbar} = useSystemContext();

  const [loading, setLoading] = useState(false);
  const zipDialog = useRef(null);

  const handleChangeZipcode = e => {
    const address = {
      ...data[columnKey],
      zipcode: e.target.value
    }
    handleChange(address);
  }
  const handleChangePrefecture = e => {
    const address = {
      ...data[columnKey],
      prefecture: e.target.value
    }
    handleChange(address);
  }
  const handleChangeAddress1 = e => {
    const address = {
      ...data[columnKey],
      address1: e.target.value
    }
    handleChange(address);
  }
  const handleChangeAddress2 = e => {
    const address = {
      ...data[columnKey],
      address2: e.target.value
    }
    handleChange(address);
  }

  const handleChange = (address) => {
    const prefecture = prefectures.filter(item=>item.code===address.prefecture);
    address.fullAddress = 
    (prefecture.length > 0 ? prefecture[0].name : "") +
    (address.address1 || "") +
    (address.address2 || "");

    setData({
      ...data,
      ...{[columnKey]: address}
    })

    const validatiorRes = rulesValidator(address, column.rules);
    const newError = validatiorRes === true? false : validatiorRes;
    setErrors({
      ...errors,
      ...{[columnKey]: newError}
    });
  }

  const getAddress = async () => {
    if(!data[columnKey].zipcode) return;
    const zip = splitZipcode(data[columnKey].zipcode);
    if(zip === false) return;

    setLoading(true);
    const res = await axios.get(`https://madefor.github.io/postal-code-api/api/v1/${zip[0]}/${zip[1]}.json`).catch(e=>{
      return false;
    });

    //住所ない・エラーの時
    if(!res) {
      showSnackbar("エラーが発生しました","secondary");
      setLoading(false);
      return false;
    }
    if(res.data.data.length <= 0) {
      showSnackbar("住所が見つかりませんでした","secondary");
      setLoading(false);
      return;
    }

    let addressData = false;
    if(res.data.data.length === 1) {  //
      addressData = res.data.data[0];
    } else {
      //複数の時、選択ダイアログ表示
      addressData = await zipDialog.current.show(res.data.data);
      //キャンセルされた時
      if(!addressData) {
        setLoading(false);
        return false;
      }
    }

    console.log(addressData);

    const address = {
      ...data[columnKey],
      prefecture: Number(addressData.prefcode),
      address1: addressData.ja.address1 + addressData.ja.address2 + addressData.ja.address3 + addressData.ja.address4
    }
    handleChange(address);
    setLoading(false);
  }

  return (
    <div className={styles.container}>
      <Typography>{column.label}</Typography>
      <TextField
        label="郵便番号"
        variant="outlined"
        margin="normal"
        required={column.required}
        onChange={handleChangeZipcode}
        error={!!errors[columnKey]}
        value={!!data[columnKey]? data[columnKey].zipcode : ""}
        disabled={disabled}
      />
      <Button
        variant="outlined"
        onClick={getAddress}
        disabled={loading}
      >{loading? "取得中..." : "郵便番号から住所入力"}</Button>
      <FormControl
        component="fieldset"
        error={!!errors[columnKey]}
        disabled={disabled}
        variant="outlined"
        required={column.required}
        fullWidth
      >
        <InputLabel>都道府県</InputLabel>
        <Select
          // labelId={'selectlabel'+columnKey}
          // id={'select'+columnKey}
          label="都道府県"
          value={!!data[columnKey] ? Number(data[columnKey].prefecture) : ""}
          onChange={handleChangePrefecture}
        >
          {prefectures.map(item=>(
            <MenuItem
              key={item.code}
              value={item.code}
            >{item.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="住所1"
        variant="outlined"
        margin="normal"
        fullWidth
        required={column.required}
        onChange={handleChangeAddress1}
        error={!!errors[columnKey]}
        value={!!data[columnKey] ? data[columnKey].address1 : ""}
        disabled={disabled}
      />
      <TextField
        label="住所2（マンション名・部屋番号等）"
        variant="outlined"
        margin="normal"
        fullWidth
        onChange={handleChangeAddress2}
        error={!!errors[columnKey]}
        helperText={errors[columnKey]}
        value={!!data[columnKey] ? data[columnKey].address2 : ""}
        disabled={disabled}
      />
      <KinuZipDialog
        ref={zipDialog}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2)
  }
}));