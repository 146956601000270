import React, { useContext, useEffect, useState } from 'react'
import firebase from '../plugins/firebase'
import {withRouter} from 'react-router-dom'
import {useSystemContext} from '../context/system'

//Context
export const AuthContext = React.createContext();

//Provider
export const AuthProvider = withRouter(({children, history}) => {
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userData, setUserData] = useState(null);
  const [firstLoading, setFirstLoading] = useState(true);
  const [userSetting, setUserSetting] = useState({});

  //const [userObserver, setUserObserver] = useState(null);

  const {hideLoading, setSidebarBadge, cacheReset} = useSystemContext();

  //ログイン処理
  const login = async (email, password) => {
    let errorCode = "";
    const res = await firebase.auth().signInWithEmailAndPassword(email, password)
    .catch(e=>{
      errorCode = e.code;
      return false;
    })

    if(res === false) {
      return errorCode;
    }
    return true;
  }

  //ログアウト処理
  const logout = async () => {
    cacheReset();
    try {
      await firebase.auth().signOut();
    } catch(e) {
      return e;
    }
    return true;
  }

  //ユーザーデータ取得して保存
  const getUserData = async (userData) => {
    //const userData = await firebase.firestore().collection('users').doc(uid).get();
    //ユーザーデータが存在する場合
    if(userData.exists) {
      const doc = userData.data();
      setUserType(doc.type);
      setUserData(doc);

      //管理者の場合
      if(doc.type === 'admin') {
        //管理者設定も読み込み
        const settings = await firebase.firestore().doc('settings/admin').get();
        if(settings.exists) {
          const settingData = settings.data();
          setUserSetting(settingData);
        }
      }

      //事業所の場合
      else if(doc.type === 'office') {
        //サイドバーバッジ設定
        const badgeData = {
          childRequest: doc.requestChildCount
        }
        setSidebarBadge(badgeData);
      }
      setFirstLoading(false);
      return true;
    }
    //存在しない時
    else {
      setUserType(null);
      setUserData(null);
      setFirstLoading(false);
      return false;
    }
  }

  //ユーザーデータのデータベース更新(private)
  const updateUserData = async (id, userData, edit = false) => {
    if(edit) {
      userData.updateAt = firebase.firestore.FieldValue.serverTimestamp();
      userData.hasProfile = true;
    }
    await firebase.firestore().collection('users').doc(id).set(userData, {merge:true});
    return true;
  }

  //メールアドレスの変更（ログインユーザー自身）
  const changeEmail = async (newEmail) => {
    const user = firebase.auth().currentUser;
    const changeRes = await user.updateEmail(newEmail)
    .catch(e=>{
      return e
    });
    if(!!changeRes) {
      return changeRes.code;
    }

    //DBも更新
    const updateData = {
      email: newEmail
    }
    await updateUserData(userId, updateData, true);
    //ユーザーデータの更新
    //await getUserData(userId);

    return true;
  }

  //パスワードの変更（ログインユーザー自身）
  const changePassword = async (newPassword) => {
    const user = firebase.auth().currentUser;
    const changeRes = await user.updatePassword(newPassword)
    .catch(e=>{
      return e
    });
    if(!!changeRes) {
      return changeRes.code;
    }

    return true;
  }

  //パスワード再設定用メールの送信
  const resetPassword = async (email) => {
    const res = await firebase.auth().sendPasswordResetEmail(email)
    .catch(e=>{
      return e;
    });
    if(!!res) return res.code;
    return true;
  }

  //ユーザーデータのオブザーバー
  let observer = null;
  console.log(observer);

  //ユーザーの変更オブザーバー
  //ユーザーのログイン状況が変わった際の処理
  useEffect(()=>{
    firebase.auth().onAuthStateChanged(async (user) => {

      //ログアウト処理時
      if(!user) {
        setUserId(null);
        setUserType(null);
        setUserData(null);
        setUserSetting({});
        setSidebarBadge(null);
        if(observer) observer();
        setFirstLoading(false);
      }
      
      //ログイン処理時
      else {
        console.log("login")
        const userRef = firebase.firestore().collection('users').doc(user.uid);
        observer = userRef.onSnapshot(snapshot => {
          console.log("onSnapUserData")
          getUserData(snapshot);
        });
        
        setUserId(user.uid);
      }
      hideLoading();
    });
  },[])

  return(
    <AuthContext.Provider
      value={{
        login,
        logout,
        userId,
        firstLoading,
        userData,
        userType,
        userSetting,
        changeEmail,
        changePassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
});


//useContext
export const useAuthContext = () => useContext(AuthContext);
