import {makeStyles, FormControlLabel, Radio, FormControl, FormLabel, RadioGroup, FormHelperText} from '@material-ui/core'
import {useSystemContext} from 'context/system'

export default function KinuRadioForm({
  columnKey,
  column,
  data,
  setData,
  errors,
  setErrors,
  disabled
}) {
  const styles = useStyles();
  const {rulesValidator} = useSystemContext();

  const handleChange = e => {
    setData({
      ...data,
      ...{[columnKey]: e.target.value}
    })

    const validatiorRes = rulesValidator(e.target.value, column.rules);
    const newError = validatiorRes === true ? false : validatiorRes;
    setErrors({
      ...errors,
      ...{[columnKey]: newError}
    });
  }

  let radioItems = [];
  const itemName = column.itemName || 'name';
  const itemValue = column.itemValue || 'value';
  for(let i = 0; i < column.items.length; i++) {
    if(typeof column.items[i] === 'string') {
      radioItems.push(
        <FormControlLabel
          key={i}
          value={column.items[i]}
          label={column.items[i]}
          control={<Radio
            required={column.required}
            checked={data[columnKey] === column.items[i]}
          />}
        />
      )
    }
    else {
      radioItems.push(
        <FormControlLabel
          key={i}
          value={column.items[i][itemValue]}
          label={column.items[i][itemName]}
          control={<Radio
            required={column.required}
            checked={data[columnKey] === column.items[i][itemValue]}
          />}
        />
      )
    }
  }

  return (
    <div className={styles.container}>
      <FormControl component="fieldset" error={!!errors[columnKey]} disabled={disabled}>
        <FormLabel component="legend">{column.label}</FormLabel>
        <RadioGroup
          aria-label={columnKey}
          name={columnKey}
          onChange={handleChange}
        >{radioItems}</RadioGroup>
        <FormHelperText>{errors[columnKey]}</FormHelperText>
      </FormControl>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2)
  }
}));