import {makeStyles, FormControlLabel, Checkbox, FormControl, FormHelperText} from '@material-ui/core'
import {useSystemContext} from 'context/system'

export default function KinuCheckboxForm({
  column, 
  columnKey, 
  data,
  setData, 
  errors, 
  setErrors,
  disabled
}) {
  const styles = useStyles();
  const {rulesValidator} = useSystemContext();

  const handleChange = e => {
    setData({
      ...data,
      ...{[columnKey]: e.target.checked}
    })

    const validatiorRes = rulesValidator(e.target.checked, column.rules);
    const newError = validatiorRes === true ? false : validatiorRes;
    setErrors({
      ...errors,
      ...{[columnKey]: newError}
    });
  }
  

  return (
    <div className={styles.container}>
      <FormControl
        error={!!errors[columnKey]}
        component="fieldset"
        required={column.required}
        disabled={disabled}
      >
        <FormControlLabel 
          label={column.label}
          control={
            <Checkbox
              name={columnKey}
              onChange={handleChange}
              checked={!!data[columnKey]}
            />
          }
        />
        <FormHelperText>{errors[columnKey]}</FormHelperText>
      </FormControl>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2)
  }
}));