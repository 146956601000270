import {makeStyles} from '@material-ui/core'

export default function ObjectDump({children}) {
  const styles = useStyles();

  let tr = [];
  if(children) {
    for(let key of Object.keys(children)) {
      tr.push(
        <tr key={key}>
          <th>{key}</th>
          <td>{children[key]}</td>
        </tr>
      )
    }
  }

  return (
    <table className={styles.container}>
      <tbody>
      {tr}
      </tbody>
    </table>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));