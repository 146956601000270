import {makeStyles, Typography, IconButton} from '@material-ui/core'
import Card from 'kinu/components/Card'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

export default function ChildNoteCard(props) {
  const styles = useStyles();
  const item = props.item;
  
  if(!item) return null;

  return (
    <Card title={item.title} className={styles.card}>
      <Typography className={styles.date}>{item.date.year}年{item.date.month}月{item.date.day}日</Typography>
      <Typography className={styles.content}>{item.content}</Typography>
      <div className={styles.tools}>
        <IconButton
          aria-label="edit"
          color="primary"
          onClick={()=>props.edit(item)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={()=>props.delete(item)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  date: {
    color: "#777",
    fontSize: 12
  },
  content: {
    marginTop: theme.spacing(1)
  },
  card: {
    position: "relative"
  },
  tools: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
}));