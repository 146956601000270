import {IconButton, makeStyles, Typography} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {DataGrid} from '@material-ui/data-grid'
import {useModelContext} from 'context/model';
import { useEffect, useImperativeHandle, useState, forwardRef } from 'react';
import TimeToStr from 'kinu/components/TimeToStr'

export default forwardRef(function KinuList(props, ref) {
  const styles = useStyles();
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState([]);

  const {getList, makeHeader} = useModelContext();

  //親からのデータ更新
  useImperativeHandle(ref, ()=>{
    return {
      updateList: () => updateList()
    }
  })

  const tools = [];
  if(!!props.delete) {
    tools.push({
      field: "delete",
      width: 65,
      headerName: "削除",
      sortable: false,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={()=>props.delete(params.row)}
        >
          <DeleteIcon />
        </IconButton>
      )
    })
  }
  if(!!props.edit) {
    tools.push({
      field: "edit",
      width: 65,
      headerName: "編集",
      sortable: false,
      renderCell: (params) => (
        <IconButton
          aria-label="edit"
          color="primary"
          onClick={()=>props.edit(params.row)}
        >
          <EditIcon />
        </IconButton>
      )
    })
  }

  let dates = []
  if(!!props.createAt) {
    dates.push({
      field: "createAt",
      width: 160,
      headerName: "作成日時",
      sortable: false,
      renderCell: (params) => (
        <TimeToStr>{params.value? params.value.seconds : ""}</TimeToStr>
      )
    })
  }
  if(!!props.updateAt) {
    dates.push({
      field: "updateAt",
      width: 160,
      headerName: "編集日時",
      sortable: false,
      renderCell: (params) => (
        <TimeToStr>{params.value? params.value.seconds : ""}</TimeToStr>
      )
    })
  }

  const columns = [
    ...tools,
    ...(props.columns || makeHeader(props.model)),
    ...dates
  ];

  const handleCellClick = e => {
    //console.log(e);
  }


  //データの取得
  const updateList = async () => {
    setLoading(true);
    const res = await getList(props.collection);
    setListData(res);
    setLoading(false)
  }
  
  useEffect(()=>{
    updateList();
  },[]);

  return (
    <div className={styles.container}>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        loading={loading}
        rows={listData}
        columns={columns}
        onCellClick={handleCellClick}
      />
    </div>
  )
})

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));