import {Avatar, ListSubheader, List, ListItem, ListItemText, ListItemAvatar, makeStyles, Typography} from '@material-ui/core'
import { useEffect, useState } from 'react';
import {useSystemContext} from 'context/system'
import {useAuthContext} from 'context/auth'
import {db} from 'plugins/firebase'
import ChildrenListItem from './childrenListItem'
import { removeUndefinedProps } from '@material-ui/data-grid';
import { useParams } from 'react-router-dom';

export default function ChildrenList(props) {
  const styles = useStyles();

  const [loading, setLoading] = useState(false);
  const [selectChild, setSelectChild] = useState(null);
  const [items, setItems] = useState([]);

  const params = useParams();

  const {userId} = useAuthContext();
  const {cacheGet} = useSystemContext();

  const collection = db.collectionGroup('children').where('officeId','==',userId).where('accept','==', true);
  const cacheKey = 'childrenList';

  const getItems = async () => {
    setLoading(true);
    const getRes = await cacheGet(collection, cacheKey);
    console.log(getRes);
    setItems(getRes.data);
    if(!!params.userId && !!params.childId ) {
      const selectChild = getRes.data.filter(item=>(item.editPath[0] === params.childId && item.editPath[1] === params.userId));
      changeChild(selectChild[0]);
    }
    setLoading(false);
  }

  const changeChild = child => {
    props.select(child);
  }

  useEffect(()=>{
    getItems();
  },[]);

  return (
    <div>
      {loading? (
        <Typography>Loading...</Typography>
      ) : (
        <List
          dense
          subheader={
            <ListSubheader component="div">
              園児選択
            </ListSubheader>
          }
        >
          {items.map(item=>(
            <ChildrenListItem
              key={item.path}
              item={item}
              select={changeChild}
            />
          ))}
        </List>
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));