import {makeStyles, Typography} from '@material-ui/core'
import KinuUserEdit from 'kinu/pages/UserCreateEdit'
import {useAuthContext} from 'context/auth'
import { useContext } from 'react';

export default function Profile() {
  const styles = useStyles();
  const {userId} = useAuthContext();

  return (
    <KinuUserEdit
      mode="edit"
      userId={userId}
    ></KinuUserEdit>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));