import {makeStyles, Typography, Button, Grid} from '@material-ui/core'
import MainLayout from 'kinu/layouts/main'
import ChildrenList from 'pages/office/note/parts/childrenList'
import ChildChatList from './parts/childChatList'

import {db} from 'plugins/firebase'
import { useState } from 'react'
import {useSystemContext} from 'context/system'
import { useHistory } from 'react-router-dom'

export default function ChatList() {
  const styles = useStyles();
  const [chat, setChat] = useState([]);
  const [selectChild, setSelectChild] = useState(null);
  const {cacheGet} = useSystemContext();

  const history = useHistory();

  const changeChild = async child => {
    setSelectChild(child);
    const path = child.path;
    await updateChat(path);
  }

  const updateChat = async path => {
    if(!path) path = selectChild.path;
    const ref = db.collection(path + '/chat').orderBy('createAt', 'desc');
    const key = "chat" + path;
    const getRes = await cacheGet(ref, key, true);
    if(!getRes.empty) {
      setChat(getRes.data);
    }
    else {
      setChat([]);
    }
  }

  return (
    <MainLayout>
      <Typography className={styles.title}>チャット一覧</Typography>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <ChildrenList
            select={changeChild}
          />
        </Grid>
        <Grid item xs={9}>
          {!!selectChild ? (
            <>
              <Typography className={styles.listTitle}>{selectChild.name}{selectChild.gender == 'boy' ? 'くん' : 'ちゃん'}のチャット</Typography>
              <ChildChatList
                updateList={updateChat}
                items={chat}
                path={selectChild.path}
              />
            </>
          ) : null}
        </Grid>
      </Grid>
    </MainLayout>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  listTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  }
}));