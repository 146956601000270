import KinuCreatePage from 'kinu/pages/ModelCreateEdit'
import InfoModel from 'models/AdminInfo'
import {useAuthContext} from 'context/auth'

export default function InfoCreate() {

  const {userId} = useAuthContext();

  return (
    <KinuCreatePage
      model={InfoModel}
      redirect="/admin/infos/list"
      path={`infos`}
    />
  )
}
