import {makeStyles, Typography} from '@material-ui/core'
import Card from 'kinu/components/Card'
import MainLayout from 'kinu/layouts/main'
import InfoList from 'pages/office/parts/AdminInfoList'
import {db} from 'plugins/firebase'
import { useEffect, useState } from 'react'

export default function AdminInfoList() {
  const styles = useStyles();

  const [infos, setInfos] = useState([]);

  const getInfos = async () => {
    const res = await db.collection('infos').get();
    if(res.empty) {
      setInfos([]);
      return;
    }
    let temp = [];
    for(let i = 0; i < res.docs.length; i++) {
      temp.push({
        ...res.docs[i].data(),
        id: res.docs[i].id
      })
    }
    setInfos(temp);
    return;
  }

  useEffect(()=>{
    getInfos();
  },[])

  return (
    <MainLayout>
    <div className={styles.container}>
      <Card title="管理者からのお知らせ">
        <InfoList
          infos={infos}
        />
      </Card>
    </div>
    </MainLayout>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4
  }
}));