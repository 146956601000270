import {makeStyles, IconButton, Button, FormLabel, Typography} from '@material-ui/core'
import {useSystemContext} from 'context/system'
import {useModelContext} from 'context/model'
import {useState} from 'react'
import Image from 'kinu/components/Image'
import ClearIcon from '@material-ui/icons/Clear';

export default function KinuImageForm({
  column,
  columnKey,
  data,
  setData,
  errors,
  setErrors,
  disabled,
}) {
  const styles = useStyles();
  const {rulesValidator} = useSystemContext();
  const {makeImages} = useModelContext();

  const handleChange = async e => {
    const images = e.target.files;

    let saveImages = [];
    for(let i = 0; i < images.length; i++) {
      const imagesRes = await makeImages(images[i], column.imageSet);
      const saveImageData = {
        images: imagesRes,
        name: images[i].name
      }
      saveImages.push(saveImageData);
    }
    setData({
      ...data,
      ...{[columnKey]: saveImages}
    })

    const validatiorRes = rulesValidator(saveImages, column.rules);
    const newError = validatiorRes === true? false : validatiorRes;
    setErrors({
      ...errors,
      ...{[columnKey]: newError}
    });
  }

  const deleteItem= removeIndex => {
    const saveImages = data[columnKey].filter((_, index) => index !== removeIndex );
    setData({
      ...data,
      ...{[columnKey] : saveImages}
    })
  }

  return (
    <div className={styles.container}>
      <FormLabel component="legend" className={styles.label}>{column.label}</FormLabel>
      <div className={styles.imagesContainer}>
        {!!data[columnKey] && data[columnKey].map((image, index) =>
          <div className={styles.imageCard} key={index}>
            <Image
              image={image.images.default}
              cover
              animationDuration={2000}
              //aspectRatio={1}
            />
            <div className={styles.tools}>
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={()=>deleteItem(index)}
              >
                <ClearIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
      <Button
        component="label"
        variant="outlined"
        color={!!errors[columnKey]? 'secondary' : 'primary'}
        //value={data[columnKey]}
        disabled={disabled}
      >
        {column.placeholder || '画像選択'}
        <input
          id={columnKey}
          name={columnKey}
          required={column.required}
          type="file"
          className={styles.inputFileButtonHide}
          onChange={handleChange}
          disabled={disabled}
          accept="image/*"
          multiple={column.multiple}
        />
        <Typography>{errors[columnKey]}</Typography>
      </Button>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2)
  },
  label: {
     marginBottom: theme.spacing(1)
  },
  inputFileButtonHide: {
    opacity: 0,
    appearance: 'none',
    position: 'absolute',
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2)
  },
  imageCard: {
    width: 200,
    height: 200,
    padding: theme.spacing(1),
    position: "relative"
  },
  tools: {
    position: "absolute",
    top: 0,
    right: 0
  }
}));