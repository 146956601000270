import React, {useContext} from 'react'
import firebase, {storage} from 'plugins/firebase'
import {useAuthContext} from 'context/auth'
import {useSystemContext} from 'context/system'
import Compressor from 'compressorjs';
import {makeItemData} from 'kinu/plugins/functions'

const ModelContext = React.createContext();

export const ModelProvider = ({children}) => {

  const {userType} = useAuthContext();
  const {fsCacheGet} = useSystemContext();

  //-----Create-----
  const createItem = async (model, data, path = null) => {
    const validationRes = await validation(model, data, 'create');
    const fsPath = path || model.path;

    //バリデーション通らなかった場合、エラー内容を返す
    if(validationRes.result === false) {
      return validationRes.errors;
    }

    //登録処理

    //新規登録時に自動で追加する項目
    const saveData = {
      ...validationRes.data,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
      updateAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    const addRes = await firebase.firestore().collection(fsPath).add(saveData)

    return true;
  }

  //-----Read-----
  const getItem = async doc => {
    const getRes = await doc.get();
    if(!getRes.exists) {
      return false;
    }

    let res = makeItemData(getRes);
    return res;
  }

  const getList = async collection => {

    const getRes = await collection.get();
    if(getRes.empty) {
      return [];
    }

    let res = [];
    for(let i = 0; i < getRes.docs.length; i++) {
      res.push(makeItemData(getRes.docs[i]));
    }

    return res
  }

  const getItemProp = async (path, prop, cache = false) => {
    let doc = null;
    try {
      doc = firebase.firestore().doc(path)
    } catch(e) {
      return false;
    }
    const res = cache ?
      await fsCacheGet(doc):
      await doc.get();
    if(!res || !res.exists) return null;

    const data = res.data();
    return data[prop];
  }

  //-----Update-----
  //data.pathが編集先
  const editItem = async (model, data) => {
    const validationRes = await validation(model, data, 'edit');

    //バリデーション通らなかった場合、エラー内容を返す
    if(validationRes.result === false) {
      return validationRes.errors;
    }

    //編集処理
    //編集時に自動で追加する項目
    const saveData = {
      ...validationRes.data,
      updateAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    const editRes = await firebase.firestore().doc(data.path)
    .set(saveData, {merge: true})

    return true;
  }

  //-----Delete------
  const deleteItem = async (path, model, nest = false) => {
    //画像が含まれれば画像データも削除する
    for(let key of Object.keys(model.columns)) {
      if(model.columns[key].type === 'image') {
        const imageData = await getItemProp(path, key);
        if(imageData) for(let i = 0; i < imageData.length; i++) {
          await deleteImage(imageData[i].imageName, imageData[i].fileId);
        }
      }
    }

    await firebase.firestore().doc(path).delete();
    return true;
  }

  //バリデーション
  const validation = async (model, validationData, mode = 'create') => {
    let res = {
      result: true,
      data: {},
      errors: {}
    }
    for(let key of Object.keys(model.columns)) {
      const column = model.columns[key];

      //hideCreate, hideEdit
      if(column.hideCreate && (!mode || mode === 'create')) {
        continue;
      }
      else if(column.hideEdit && mode === 'edit') {
        continue;
      }

      //showTypes
      if(!!column.showTypesForCreate && (!mode || mode === 'create')) {
        if(column.showTypesForCreate.indexOf(userType) === -1) continue;
      }
      else if(!!column.showTypesForEdit && mode === 'edit') {
        if(column.showTypesForEdit.indexOf(userType) === -1) continue;
      }

      //編集の時、送られてこない項目は無視
      if(mode === 'edit' && validationData[key] === undefined) {
        continue;
      }

      const rules = column.rules || [];
      const lazyRules = column.lazyRules || [];
      const checkRules = [
        ...rules,
        ...lazyRules
      ]
      if(!!checkRules) {
        let hasError = false;
        for(let i = 0; i < checkRules.length; i++) {
          const ruleRes = await checkRules[i](validationData[key]);
          //ルールエラーの時
          if(ruleRes !== true) {
            res.errors[key] = ruleRes;
            res.result = false;
            hasError = true;
            break;
          }
        }
        if(hasError === false) {
          res.data[key] = validationData[key];
        }
      }
      
      //ルール無い項目の時
      else {
        if(validationData[key] !== undefined) {
          res.data[key] = validationData[key];
        }
      }
    }

    //画像・ファイルのアップロード・削除
    if(res.result === true) {
      const uploadRes = await dataUpload(model, res.data, validationData.path)
      res.data = {
        ...res.data,
        ...uploadRes
      }
    }

    return res;
  }

  //リストのヘッダー作成
  const makeHeader = (model, id = true) => {
    let columns = [];
    if(id) {
      columns.push({
        field: 'id',
        headerName: "ID",
        width: 230
      })
    }

    for(let key of Object.keys(model.columns)) {
      if(!model.columns[key].hideHeader) {
        columns.push({
          field: key,
          headerName: model.columns[key].label,
          width: model.columns[key].headerWidth || 120
        });
      }
    }

    return columns;
  }

  //編集データの抽出
  const compareEditData = (data, originalData) => {
    //編集されたデータだけ渡す
    let editData = {};
    for(let key of Object.keys(data)) {
      if(originalData[key] === undefined || key === 'path') {
        editData[key] = data[key]
      }
      else {
        if(originalData[key] !== data[key]) {
          editData[key] = data[key];
        }
      }
    }
    return editData;
  }

  //フォームの初期値を作成
  const makeInitialState = (model, mode = 'create') => {
    console.log("call inital");

    let initialState = {};
    for(let key of Object.keys(model.columns)) {
      const column = model.columns[key];

      switch(column.type) {
        case "text":
          initialState[key] = "";
          break;
        case "textarea":
          initialState[key] = "";
          break;
        case "tel":
          initialState[key] = "";
          break;
        case "radio":
          initialState[key] = null;
          break;
        case "checkbox":
          initialState[key] = null;
          break;
        case "select":
          initialState[key] = "";
          break;
        case "select":
          initialState[key] = {};
          break;
        case "address":
          initialState[key] = {};
          break;
        case "image":
          initialState[key] = [];
          break;
        default:
          break;
      }
    }
    return initialState;
  }

  //---画像関連---
  const makeImages = async (imageFile, imageSet) => {
    let resImages = {};
    for(let key of Object.keys(imageSet)) {
      const imageRes = await makeImage(imageFile, imageSet[key])
      if(imageRes.result === true) {
        resImages[key] = {
          image: imageRes.image,
          url: imageRes.url,
          //set: 
          option: imageSet[key]
        }
      }
    }

    return resImages;
  }

  const makeImage = (imageFile, options) => {
    return new Promise((resolve, reject) => {
      const newOption = {
        ...options,
        success(blob) {
          resolve({
            result: true,
            image: blob,
            url: URL.createObjectURL(blob)
          });
        },
        error(e) {
          resolve({
            result: false,
            error: e
          });
        }
      }
      new Compressor(imageFile, newOption);
    })
  }

  //アップロードが必要なデータがあればアップロード
  const dataUpload = async (model, data, path) => {
    let uploadQueue = [];
    let mergeData = {};
    for(let key of Object.keys(data)) {

      //画像のアップロード
      if(model.columns[key].type === 'image') {
        let outputImageData = [];

        //現在の画像のリスト取得
        let deleteList = [];
        if(!!path) {
          deleteList = await getItemProp(path, key);
        }

        //画像の数ループ
        for(let i = 0; i < data[key].length; i++) {

          //新たにアップロードされた画像でなければ
          if(!!data[key][i].fileId) {
            outputImageData.push(data[key][i]);
            deleteList = deleteList.filter(item=>item.fileId !== data[key][i].fileId);
            continue;
          }

          const fileId = getUniqueStr();
          let tempImageData = {
            name: data[key][i].name,
            fileId,
            imageName: model.columns[key].imageName,
            images: {}
          };

          //画像セットの数ループ
          for(let setKey of Object.keys(data[key][i].images)) {
            const imageSetData = data[key][i].images[setKey];
            //アップロードするデータなければそのまま返す
            // if(!imageSetData.image) {
            //   tempImageData.images[setKey] = imageSetData;
            //   continue;
            // }

            //画像のFileオブジェクトがある時
            const ext = getExt(imageSetData.image.name)
            const path = 
            "images/" + model.columns[key].imageName + '/' + fileId + '/' + setKey + ext;
            tempImageData.images[setKey] = {
              path
            }

            //アップロード
            uploadQueue.push(upload(imageSetData.image, path));

          }
          outputImageData.push(tempImageData);

        }
        //不要な画像の削除
        if(!!deleteList) {
          let deleteImages = [];
          for(let i = 0; i < deleteList.length; i++) {
            //const path = 'images/' + deleteList[i].imageName + '/' + deleteList[i].fileId 
            deleteImages.push(deleteImage(deleteList[i].imageName, deleteList[i].fileId));
          }
          await Promise.all(deleteImages);
        }
        mergeData[key] = outputImageData;
      }

      //ファイルのアップロード
      else if(model.columns[key].type === 'file') {
      }


    }
    const uploadRes = await Promise.all(uploadQueue);
    const resData = {
      ...data,
      ...mergeData
    }
    return resData;
  }

  //画像セットの削除
  const deleteImage = async (imageName, fileId) => {
    const path = 'images/' + imageName + '/' + fileId;
    const list = await storage.ref().child(path).listAll();
    let deleteItems = [];
    list.items.forEach(imageRef => {
      deleteItems.push(imageRef.delete());
    })
    const res = await Promise.all(deleteItems);
    return
  }

  //Readの時に自動的に追加するデータ
// const makeItemData = doc => {
//   return {
//     ...doc.data(),
//     id: doc.id,
//     path: doc.ref.path,
//     editPath: makeEditPath(doc.ref)
//   }
// }

  return(
    <ModelContext.Provider value={{
      createItem,
      getItem,
      getList,
      getItemProp,
      editItem,
      deleteItem,
      makeHeader,
      makeInitialState,
      validation,
      makeImages,
      compareEditData,
      makeItemData
    }}>
      {children}
    </ModelContext.Provider>
  )
}

//useContext
export const useModelContext = () => useContext(ModelContext);

//----private-----

//編集時のリンク用の親のIDの配列
// const makeEditPath = ref => {
//   let ids = [ref.id];
//   let checkRef = ref;
//   while(!!checkRef.parent.parent) {
//     checkRef = checkRef.parent.parent;
//     ids.push(checkRef.id);
//   }

  //必ずしも親のIDが必要なわけではないので、あとから加工できるよう配列で返却
  // let editPath = "";
  // for(let i = ids.length - 1; i >= 0; i--) {
  //   editPath += ids[i];
  //   if(i !== 0) {
  //     editPath += "/"
  //   }
  // }

//   return ids;
// }

/*
 * ユニークな文字列の生成（タイムスタンプベース）
 */
const getUniqueStr = myStrong => {
  const strong = myStrong || 1000;
  return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
}

/*
 * 拡張子の取得
 */
const getExt = path => {
  const ext = path.match(/[^.]+$/);
  return ext? "." + ext : "";
}

const upload = async (file, path) => new Promise((resolve, reject) => {
  const uploadRef = storage.ref().child(path);
  uploadRef.put(file).then(snapshot => {
    resolve(true);
  })
  .catch(e=>{
    resolve(false);
  })
})

const deleteUploadData = async (path) => {

}
