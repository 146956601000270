import React, { useState } from 'react'
import {
  makeStyles,
  Typography,
  Container,
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import BlankLayout from 'kinu/layouts/blank'
import { useSystemContext } from "context/system"
import {useAuthContext} from 'context/auth'
import {Link} from 'react-router-dom'

export default function Login() {
  const styles = useStyles();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {showLoading, hideLoading, showSnackbar} = useSystemContext();
  const {resetPassword} = useAuthContext();

  //ログイン処理
  const sendResetPassword = async () => {
    showLoading();
    const res = await resetPassword(email);
    if(res !== true) {
      switch(res) {
        case "auth/invalid-email":
          setErrorMessage("正しいメールアドレスを入力して下さい");
          break;
        default:
          setErrorMessage("エラーが発生しました");
          break;
      }
    }
    else {
      showSnackbar('パスワード再設定用のメールを送信しました');
    }
    hideLoading();
  }

  return (
    <BlankLayout>
      <Container maxWidth="xs">
      <div className={styles.paper}>
        <Typography component="h1" variant="h5">
          パスワード再設定
        </Typography>
        <form className={styles.form} noValidate onSubmit={e=>e.preventDefault()}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            onChange={e=>setEmail(e.target.value)}
            onKeyDown={e=>{
              if(e.keyCode === 13) {
                sendResetPassword();
              }
            }}
            autoFocus
          />
          <Typography className={styles.errorMessage}>{errorMessage}</Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={styles.submit}
            onClick={()=>sendResetPassword()}
          >
            パスワード再設定
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/login" variant="body2">
                ログイン画面へ戻る
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      </Container>
    </BlankLayout>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3,0,2)
  },
  errorMessage: {
    color: "red"
  }
}));