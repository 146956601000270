import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core'
import React from 'react';
import {AuthContext} from '../../context/auth'
import firebase from '../../plugins/firebase'

class ReAuthDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showResolve: null,
      loading: false,
      password: "",
      message: "現在のパスワードを入力して下さい"
    }
  }

  static contextType = AuthContext;

  //表示
  show = () => new Promise((resolve, reject) => {
    this.setState({open:true});
    this.setState({showResolve:resolve});
  })

  //パスワード入力
  _handleChange = e => {
    this.setState({password:e.target.value});
  }

  //キャンセル
  _handleCancel = () => {
    this.setState({open:false});
    this.state.showResolve(false)
  }

  //認証
  _handleAuth = async () => {
    this.setState({loading:true});
    this.setState({message:"認証中...しばらくお待ち下さい"})
    const email = this.context.userData.email;
    const password = this.state.password;
    const user = firebase.auth().currentUser;
    
    const credential = firebase.auth.EmailAuthProvider.credential(email, password)
    const reAuthRes = await user.reauthenticateWithCredential(credential)
    .catch(e=>{
      this.setState({message:"パスワードが間違っています"})
      return false;
    })

    this.setState({loading:false});
    if(reAuthRes === false) {
      return false;
    }

    this.setState({open:false})
    this.state.showResolve(true)
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>再認証</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.message}
          </DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="password"
            label="パスワード"
            type="password"
            fullWidth
            onChange={this._handleChange}
            disabled={!!this.state.loading}
            onKeyDown={e=>{
              if(e.keyCode === 13) {
                this._handleAuth();
              }
            }}
          />
          <DialogActions>
            <Button
              onClick={this._handleCancel}
              disabled={!!this.state.loading}
            >キャンセル</Button>
            <Button
              onClick={this._handleAuth}
              disabled={!!this.state.loading}
            >{this.state.loading? "認証中...": "再認証"}</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }
}
export default ReAuthDialog;